@import "custom-variable";

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

ul {
    list-style-type: none;
}

a {
    text-decoration: none !important;
}

img {
    // width: 100%;
}

html,
body {
    font-size: 1vw;
}


@import "custom-variable";

html,
body {
    height: 100%;
    width: 100%;
    min-height: 100%;
    // overflow: auto;
    // -webkit-overflow-scrolling: auto;
}

#root {
    min-height: 100%;
    display: flex;
    flex-direction: column;
}


.disable {
    .opcity-block-box {
        pointer-events: none;

        // opacity: 0.6;
        .softskill-box {

            // background-color: darkgray;
            .start-btn {
                button {
                    background-color: darkgray;
                    box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.71);
                }
            }
        }
    }
}

.btn-create-report-width {
    padding-right: 20px;
    padding-left: 20px;
}



.allcomplete {
    pointer-events: none;

    .custome-btn {
        background-color: darkgray;
    }
}

.profile-img {
    background-color: #bcdfff;
    width: 50px;
    border-radius: 30px;
    align-items: center;
    justify-content: center;
    display: flex;
    height: 50px;
}

.badges {
    text-align: center;
    box-sizing: border-box;
    height: 21.53px;
    width: 21.73px;
    border: 2.4px solid #180148;
    background-color: #bf06ff;
    border-radius: 10px;
    color: #ffffff;
    font-size: 11px;
    font-weight: $font-medium;
    font-family: "public-sans";
    position: absolute;
    right: -10px;
    top: -5px;
}

.sticky-header {
    position: relative;
    height: 1px;
    background-color: #eef7ff;
}

.wave {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
}

.wave-shape {
    display: inline-block;
    position: relative;
    width: 100%;
    padding-bottom: 26%;
    vertical-align: middle;
    overflow: hidden;

    .shape-box {
        stroke: none;
        fill: $color-Alice-Blue;
    }
}

.ovcer {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 11;
}

.hole {
    position: absolute;
    width: 150px;
    height: 150px;
    border-radius: 50%;
    box-shadow: 0 0 0 9999px rgba(0, 0, 0, 0.8);
}

.result-overlay {
    .hole {
        top: 14.5rem;
        left: 10.5rem;

        @media (max-width: 992px) and (min-width: 768px) {
            top: 13.5rem;
            left: 1rem;
        }

        @media (max-width: 767px) {
            top: 10rem;
            left: 1rem;
        }

        @media (max-width: 576px) {
            top: 14rem;
            left: 0.7rem;
            width: 190px;
            height: 190px;
        }
    }
}

.qustion-overlay {
    .hole {
        top: 7rem;
        left: 5rem;

        @media (max-width: 992px) and (min-width: 768px) {
            top: 6rem;
            left: -1rem;
        }

        @media (max-width: 767px) {
            top: 5.5rem;
            left: -1rem;
        }

        @media (max-width: 576px) {
            top: 4rem;
            left: 0.2rem;
            width: 190px;
            height: 190px;
        }
    }
}

.circle {
    stroke-width: 140;
    stroke-linecap: round;
    opacity: 0.8;
}

.circle-box {
    background-color: black;
    opacity: 0.5;
    position: fixed;
    z-index: 1;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100vw;
    height: 100vh;

    svg {
        position: absolute;
        top: 0;
        left: 0;
        width: 100vw;
        height: 100vh;
    }
}

.overlay {
    position: fixed;
    background: #000000c4;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 11;
}

.maskoverlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 11;
}

.opacity2 {
    fill-opacity: 0.8;
    fill: black;
}

.text-size {
    width: 244px;
}

// .titleWidthSize{
//   width: 185px;
// }

.breadcrumbs-box {
    z-index: 1;
    min-height: 24px;
}

.lu_logo {
    position: absolute;
    left: 0;
    right: 0;
    text-align: center;
    z-index: -1;
}

@media (max-width: 767px) {
    .try-exmple {
        .mobile-footer-menu {
            display: none;
        }
    }
}

.donut-box {
    // background-color: blue;
    width: 216px;
    height: 216px;
    margin-left: auto;
    margin-right: auto;
    position: relative;

    .slice-size {
        width: 108px;
        height: 108px;
        background-repeat: no-repeat;
        background-size: 100% 100%;
        background-repeat: no-repeat;
        position: absolute;
    }

    .one-slice {
        top: 0px;
        right: 0px;
        background-image: url("../../Images/inactive_graph/one__inactive.png");
    }

    .two-slice {
        bottom: 0px;
        right: 0px;
        background-image: url("../../Images/inactive_graph/two_inactive.png");
    }

    .three-slice {
        bottom: 0px;
        left: 0px;
        background-image: url("../../Images/inactive_graph/three_inactive.png");
    }

    .four-slice {
        top: 0px;
        left: 0px;
        background-image: url("../../Images/inactive_graph/four_inactive.png");
    }

    .activeSlice.one-slice {
        background-image: url("../../Images/active_graph/one__active.png");
    }

    .activeSlice.two-slice {
        background-image: url("../../Images/active_graph/two_active.png");
    }

    .activeSlice.three-slice {
        background-image: url("../../Images/active_graph/three_active.png");
    }

    .activeSlice.four-slice {
        background-image: url("../../Images/active_graph/four_active.png");
    }
}


// .wave-section {
//   min-height: calc(100vh - 14rem);
// }

// height on mobile fit
@media (min-width: 576px) {

    // .wave-section1{
    //   min-height: calc(100vh - (208px));
    // }
    .endresult-screen {
        min-height: calc(100vh - 120px) !important;
    }

    .wave-section {
        min-height: calc(100vh - (88px + 206px));
    }

    .join-company-height {
        min-height: calc(100vh - (88px + 206px));
    }

    .header-minusHeight {
        min-height: calc(100vh - (88px)) !important;
    }

    .share-blue-screen {
        min-height: calc(100vh - (48px + 166px)) !important;
    }

    .headerFooter-minusHeight {
        min-height: calc(100vh - (88px));
    }

    .headerMe-Height {
        min-height: calc(100vh - 136px) !important;
    }

    // .lp-screen{
    //   min-height: calc(100vh - (88px + 305px));
    // }
}

@media (max-width: 767px) {
    .headerFooter-minusHeight {
        min-height: calc(100vh - (68px));
    }

    .join-company-height {
        min-height: calc(100vh - (88px + 410px));
    }

    .wave-section {
        min-height: calc(100vh - (208px));
    }
}

@media (max-width: 575px) {
    .mob-height-imp {
        min-height: calc(100vh - (10vh)) !important;
    }

    .mob-height {
        min-height: 100vh;
    }

    .wave-section {
        min-height: calc(100vh - (208px));
    }

    .lp-screen {
        min-height: calc(100vh - (305px));
    }
}

.elevated {
    // float: right;
    margin: 2;
    z-index: 1;
    background-color: $color-Alice-Blue;
    position: relative;
}

.footer-wave {
    position: relative;
    min-height: 13rem;
    background-color: #1c024b;

    // padding-bottom: 200px !important;
    // @media (max-width: 575px) {
    //   padding-bottom: 300px !important;
    // }
    &::before {
        content: "";
        background-image: url("../../Images/Desktop_curve.svg");
        background-color: #eef7ff;
        width: 100%;
        background-size: 100% 100%;
        background-repeat: no-repeat;
        height: 112px;
        position: absolute;
        top: -16px;

        @media (max-width: 575px) {
            background-image: url("../../Images/Mobile_curve.png");
            height: auto;
            position: initial;
            display: block;
            padding-bottom: 15%;
            background-position-y: 1px;
        }

        @media (min-width: 1400px) {
            background-size: cover;
        }

        @media (min-width: 1440px) {
            background-size: 100% auto;
            // height: 160px;
        }
    }
}

.loader {
    top: 0;
    background-color: rgba(238, 247, 255, 0.88);
    z-index: 999;
    bottom: 0;

    // img {
    //   width: 100%;
    //   max-width: 25px;
    // }
}

.content-heading {
    border-bottom: 1px solid #e7e7e7;
}

.disable-btn {
    pointer-events: none;
    opacity: 0.5;
    background-color: $color-Grey !important;
}

//====================common scss =======================

html,
body {
    font-size: 1vw;
    height: 100%;
    width: 100%;
    overscroll-behavior-y: none;
    background-color: #FCFAFE;

    @media (max-width: 900px) {
        font-size: 2vw;
    }

    @media (max-width: 767px) {
        font-size: 4vw;
    }
}

button {
    -webkit-appearance: none;
}

input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
    -webkit-appearance: none;
}

.form-control[readonly] {
    background-color: transparent;
}

.full-height {
    min-height: 100vh;
}

input:-internal-autofill-selected {
    background-color: transparent !important;
}

.bg-aliceBlue {
    background-color: $color-Alice-Blue;
}

.bg-white {
    background-color: $color-White;
}

.bg-violet {
    background-color: $color-Violent-Violet;
}

.bg-dithered {
    background-color: $color-dithered;
}

.bg-Electric-Purple {
    background-color: $color-Electric-Purple;
}

.bg-color-pink {
    background-color: $color-pink;
}

.bg-lightBlue {
    background-color: $color-lightBlue;
}

.bg-Cyan {
    background-color: $color-cyan;
}

.background-transparent {
    background-color: transparent;
}

.btn:focus {
    outline: none;
}

input:focus {
    outline: none;
}

a {
    &:hover {
        text-decoration: none;
    }
}

.hide-logo {
    .hide {
        display: none;
    }
}

.capitalsText {
    text-transform: capitalize;
}

input::-webkit-credentials-auto-fill-button {
    display: none !important;
}

// input[autocomplete="off"]::-webkit-contacts-auto-fill-button,
input[autoComplete="off"]::-webkit-credentials-auto-fill-button {
    visibility: hidden;
    display: none !important;
    pointer-events: none;
    height: 0;
    width: 0;
    margin: 0;
}

button:focus {
    outline: none;
}

.img100 {
    width: 100%;
    display: block;
}

.img50 {

    width: 20%;
    display: block;
}

.min-width-178 {
    min-width: 178px;
}

.min-width-240 {
    min-width: 240px;
}

.min-width-296 {
    min-width: 296px;
}

.min-width-245 {
    min-width: 245px;
}

.text-w-203 {
    width: 203px;
}

.text-w-190 {
    width: 190px;
}

.init-bottom-right {
    top: -4.5rem;
    right: 2rem;
}

.init-top {
    top: 0px;
    left: 0px;
    right: 0px;
}

.margin-top-110 {
    // margin-top: 110px;
    margin-top: 145px;

    @media (max-width: 575px) {
        margin-top: 225px;
    }
}

.letter-space-085 {
    letter-spacing: 0.85px;
}

.pl-18 {
    padding-left: 18px;
}

.mb-32 {
    margin-bottom: 32px;
}

.border-2 {
    border: 2px solid;
}

.border-color-white {
    border-color: $color-White;
}

.color-white {
    color: $color-White;
}

.border-color-blue {
    border-color: $color-blue;
}

.btn-circle-30 {
    border-radius: 30px;
}

.font-family-ubuntu {
    font-family: "public-sans";
}

.font-weight-bold {
    font-weight: $font-bold;
}

.font-weight-medium {
    font-weight: $font-medium;
}

.font-weight-300 {
    font-weight: $font-xl;
}

.font-weight-regular {
    font-weight: $font-regular;
}

.line-height-32 {
    line-height: $line-height32;
}

.line-height-22 {
    line-height: $line-height22;
}

.line-height-17 {
    line-height: $line-height17;
}

.line-height-18 {
    line-height: $line-height18;
}

.line-height-20 {
    line-height: $line-height20;
}

.font-size-30 {
    font-size: $font-30;
}

.font-size-22 {
    font-size: $font-22;
}

.font-size-20 {
    font-size: $font-20;
}

.font-size-18 {
    font-size: $font-18;
}

.font-size-16 {
    font-size: $font-16;
}

.font-size-15 {
    font-size: $font-15;
}

.font-size-14 {
    font-size: $font-14;
}

.font-size-13 {
    font-size: $font-13;
}

.font-size-11 {
    font-size: $font-11;
}

.text-color-sapphire {
    color: $color-Sapphire;
}

.text-color-violet-forgot {
    color: #006BF1;
    font-size: 21px;
    font-weight: 700;
    line-height: 1.524;
}

.text-color-violet {
    color: $color-Violent-Violet;
}

.text-color-violet2 {
    color: $color-violet;
}

.text-color-blue {
    color: $color-Navy-Blue;
}

.text-color-black {
    color: $color-black;
}

.text-color-aliceBlue {
    color: $color-Alice-Blue;
}

.text-color-purple {
    color: $color-Electric-Purple;
}

.text-color-darkgray {
    color: $color-Grey;
}

.text-color-cyan {
    color: $color-cyan;
}

.text-color-gray {
    color: #d2d2d2;
}

.text-color-white {
    color: #fff;
}

.text-black {
    color: #000;
}

.text-color-darkblue {
    color: #04226b;
}

.border-color-darkblue {
    border-color: #04226b;
}

.opcaity-06 {
    opacity: 0.6;
}

.error {
    color: red;
    font-family: "public-sans";
    font-size: $font-13;
    font-weight: $font-medium;
    letter-spacing: 0;
    line-height: $line-height18;
}

//=========================for custome checkbox
/* The container */
.checkbox-container {
    display: block;
    position: relative;
    padding-left: 35px;
    margin-bottom: 12px;
    cursor: pointer;
    font-size: 22px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

/* Hide the browser's default checkbox */
.checkbox-container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}

/* Create a custom checkbox */
.checkmark {
    position: absolute;
    top: 5px;
    left: 0;
    height: 25px;
    width: 25px;
    border: 1px solid #dcdcdc;
    border-radius: 3px;
    background-color: transparent;
    outline: none;
    border: none;

    @media (min-width: 576px) {
        // border: 1px solid #1c004b;
        background-color: #fff;

    }
}

/* On mouse-over, add a grey background color */
// .checkbox-container:hover input ~ .checkmark {
//   background-color: #ccc;
// }

/* When the checkbox is checked, add a blue background */
.checkbox-container input:checked~.checkmark {
    background-color: #1c004b;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
    content: "";
    position: absolute;
    display: none;
}

/* Show the checkmark when checked */
.checkbox-container input:checked~.checkmark:after {
    display: block;
}

/* Style the checkmark/indicator */
.checkbox-container .checkmark:after {
    left: 9px;
    top: 3px;
    width: 7px;
    height: 13px;
    border: solid white;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}

.custom-progress-style {
    background-color: $color-Whisper;
    border: 0px;
    height: 6px;
    border-radius: 3px;
    overflow: hidden;
}

// =============== ==== ========custom radio button
.radio-text {
    margin-top: -2px;
}

/* The container */
.radio-container {
    display: block;
    position: relative;
    padding-left: 25px;
    // margin-bottom: 8px;
    cursor: pointer;
    // font-size: 22px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

/* Hide the browser's default radio button */
.radio-container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
}

/* Create a custom radio button */
.radio-checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 16px;
    width: 16px;
    background-color: #fff;
    border: 1px solid #180148;
    border-radius: 50%;
}

/* On mouse-over, add a grey background color */
// .radio-container:hover input ~ .radio-checkmark {
//   background-color: #ccc;
// }

/* When the radio button is checked, add a blue background */
.radio-container input:checked~.radio-checkmark {
    background-color: #180148;
}

/* Create the indicator (the dot/circle - hidden when not checked) */
.radio-checkmark:after {
    content: "";
    position: absolute;
    display: none;
}

/* Show the indicator (dot/circle) when checked */
.radio-container input:checked~.radio-checkmark:after {
    display: block;
}

/* Style the indicator (dot/circle) */
.radio-container .radio-checkmark:after {
    top: 3px;
    left: 3px;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background: white;
}

.small-width {
    width: 80px;
}

.first-letter:first-letter {
    text-transform: uppercase;
}

// =================common css End===============

//================popup style =================

.popup-custom-box.summary-popup {
    .assessment-card {
        margin-right: auto;
        margin-left: auto;
    }

    @media (min-width: 576px) {
        .modal-dialog {
            max-width: 428px;
            width: 100%;
        }
    }

    @media (max-width: 575px) {
        .modal-dialog {
            margin: 0px 1.5rem;
        }

        .congratulation-card {
            margin: 5px 16px;
        }
    }
}

.popup-custom-box {
    @media (min-width: 576px) {
        .modal-dialog {
            max-width: 328px;
            width: 100%;
        }
    }

    @media (max-width: 575px) {
        .modal-dialog {
            margin: 0px 1.5rem;
        }
    }

    .modal-content {
        padding: 16px;
        padding: 0;
        border-radius: 16px 6px 16px 6px;
        background-color: #fff;
        box-shadow: 0 0 11px 6px rgba(4, 34, 107, 0.11);
        // overflow: hidden;
        border: 0px;
    }

    .modal-body {
        padding: 0px;

        .footer-popup-wave {
            border-bottom-left-radius: 6px;
            border-bottom-right-radius: 6px;
        }
    }
}

.icon-width-112 {
    width: 100%;
    max-width: 112px;
}

.footer-popup-wave {

    // margin-left: -1px;
    // border-bottom-left-radius:6px;
    // border-bottom-right-radius:10px;
    &:before {
        content: "";
        background-image: url("../../Images/Mobile_curve.png");
        background-color: #fff;
        width: 100%;
        background-repeat: no-repeat;
        background-size: 100% 100%;
        padding-bottom: 20%;
        display: block;
        background-position: 100% 2px;
    }
}

.mobile-bg-transparent {
    .breadcrumbs-layer {
        background-color: transparent;
        border-bottom: 0px;
    }
}

@media (max-width: 767px) {
    .mob-order-2 {
        order: 2;
    }
}

// ================ component scss start ============

.field-style {
    label {
        color: $color-black;
        font-family: "public-sans";
        font-size: $font-13;
        font-weight: $font-medium;
        letter-spacing: 0;
        line-height: $line-height18;
        margin-bottom: 5px;
    }

    input {
        color: $color-black;
        font-family: "public-sans";
        font-size: $font-13;
        font-weight: $font-medium;
        height: 48px;
        width: 100%;
        border: none;
        outline: none;
        // border: 1px solid $color-Violent-Violet;
        border-radius: 3px;
        background: #fff;
    }
}

.signup-screen {
    .tnc-style {
        label {
            line-height: 2.5;
            display: inline-block;
        }
    }
}

.tnc-style {
    .form-check {
        padding-left: 0rem;
    }

    label {
        // color: $color-violet;
        // font-family: "public-sans";
        // font-size: $font-13;
        // font-weight: $font-medium;
        // line-height: 2.5;
        color: rgba(55, 55, 55, 1);
        font-family: "public-sans";
        font-size: 15px;
        font-weight: 400;
        font-style: normal;
        letter-spacing: 0px;
        text-align: left;
        line-height: 32px;
    }

    input[type="checkbox"] {
        height: 25px;
        width: 25px;
        border: 1px solid $color-violet;
        border-radius: 3px;
        margin-left: -2.5rem;
    }
}

.readonly-field-style {
    margin-bottom: 0px;

    .box-wrp {
        border-radius: 10px 3px 10px 3px;
        background-color: #ffffff;
        box-shadow: 0 0 6px 3px rgba(4, 34, 107, 0.11);
    }

    .insde-space {
        padding: 0.7rem 1.5rem;
    }

    input[type="text"] {
        border: 0px;
        background-color: transparent;
        border-radius: 0px;
    }

    input:focus {
        outline: none;
        box-shadow: none;
    }
}

.company_code {
    .company-codebox {
        height: 48px;
        border: 1px solid #dcdcdc;
        border-radius: 3px;
        color: $color-violet;
        font-family: "public-sans";
        font-size: $font-16;
        font-weight: $font-medium;

        &:focus {
            box-shadow: none;
        }
    }

    .companycodegrp-btn {
        button {
            width: 296px;

            @media (max-width: 575px) {
                width: 275px;
            }
        }
    }
}

.companycode-model {
    .modal-dialog {
        max-width: 328px;

        .modal-content {
            border-radius: 16px 6px 16px 6px;
            background-color: transparent !important;
            box-shadow: 0 0 11px 6px rgba(4, 34, 107, 0.11);
            border: 0px;

            .popup-content {
                border-radius: 16px 6px 16px 6px;
                overflow: hidden;
            }
        }
    }
}

.breadcrumbs-layer {
    padding-top: 12px;
    padding-bottom: 12px;
    background-color: $color-White;
    border-bottom: 1px solid $color-Solitude;
}

.breadcrumbs-box {
    .back-icon {
        max-width: 24px;
    }

    .lunemai-logo {
        max-width: 35px;
    }
}

.softskill-box {
    min-height: 78px;
    width: 327px;
    border-radius: 10px 3px 10px 3px;
    background-color: $color-Navy-Blue;
    box-shadow: 0 0 6px 3px rgba(4, 34, 107, 0.11);
    padding: 18px;

    @media (max-width: 575px) {
        width: 100%;
    }

    .icon-soft {
        img {
            width: 29px;
            display: block;
        }
    }

    .titles {
        color: $color-White;
        font-family: "public-sans";
        font-size: $font-18;
        font-weight: $font-bold;
        letter-spacing: 0;
        line-height: $line-height18;
        margin-bottom: 0px;
    }

    .sub-titles {
        color: $color-White;
        font-family: "public-sans";
        font-size: $font-13;
        font-weight: $font-regular;
        letter-spacing: 0;
        line-height: $line-height18;
        margin-top: 5px;
        margin-bottom: 0px;
    }

    .start-btn {
        button {
            border: none;
            height: 31px;
            width: 78px;
            border-radius: 3px;
            background-color: $color-White;

            color: $color-Navy-Blue;
            font-family: "public-sans";
            font-size: $font-14;
            font-weight: $font-medium;
            letter-spacing: 0;
            line-height: $line-height18;
        }
    }

    .right-position {
        bottom: -1px;
        right: -1px;
    }
}

.floating {
    height: 50px;
    width: 327px;
    border-radius: 10px 3px 10px 3px;
    background-color: $color-White;
    box-shadow: 0 0 6px 3px rgba(4, 34, 107, 0.11);
    padding: 2%;
    bottom: 88%;
    right: 4%;
    flex-direction: row;

    .card-icon {
        width: 30px;
    }

    .contents {
        .inner-box1 {
            width: 190px;
        }

        .title {
            color: $color-black;
            font-family: "public-sans";
            font-size: $font-16;
            font-weight: $font-bold;
            letter-spacing: 0;
            line-height: $line-height18;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }

        .category {
            opacity: 0.6;
            color: $color-Grey;
            font-family: "public-sans";
            font-size: $font-11;
            letter-spacing: 0;
            line-height: $line-height18;
            margin-bottom: 0px;
        }

        .complete-text {
            color: $color-Electric-Purple;
            font-family: "public-sans";
            font-size: $font-11;
            letter-spacing: 0;
            line-height: $line-height18;
            margin-bottom: 0px;
        }

        .check-icon {
            width: 18px;
            height: 18px;
        }
    }
}

.text-trim {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.active-learning {
    height: 73px;

    border-radius: 10px 3px 10px 3px;
    background-color: $color-White;
    box-shadow: 0 0 6px 3px rgba(4, 34, 107, 0.11);

    @media (max-width: 575px) {
        width: 100%;
    }

    .card-icon {
        width: 30px;
    }

    .contents {
        // .inner-box1 {
        //   max-width: 166px;
        //   width: 100%;
        // }

        .title {
            // width: 120px;
            color: $color-black;
            font-family: "public-sans";
            font-size: $font-16;
            font-weight: $font-bold;
            letter-spacing: 0;
            line-height: $line-height18;
        }

        .category {
            opacity: 0.6;
            color: $color-Grey;
            font-family: "public-sans";
            font-size: $font-11;
            letter-spacing: 0;
            line-height: $line-height18;
            margin-bottom: 0px;
        }

        .complete-text {
            color: $color-Electric-Purple;
            font-family: "public-sans";
            font-size: $font-11;
            letter-spacing: 0;
            line-height: $line-height18;
            margin-bottom: 0px;
        }

        .check-icon {
            width: 18px;
            height: 18px;
            //border-radius: 50%;
        }
    }
}

.category-progress {
    height: 73px;
    width: 327px;
    border-radius: 10px 3px 10px 3px;
    background-color: transparent;
    box-shadow: 0 0 6px 3px rgba(4, 34, 107, 0.11);

    // padding: 3%;
    // bottom: 85%;
    // left: 4%;
    // flex-direction: row;
    .card-icon {
        width: 30px;
    }

    .contents {
        .inner-box1 {
            width: 190px;
        }

        .title {
            color: $color-black;
            font-family: "public-sans";
            font-size: $font-16;
            font-weight: $font-bold;
            letter-spacing: 0;
            line-height: $line-height18;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }

        .category {
            opacity: 0.6;
            color: $color-Grey;
            font-family: "public-sans";
            font-size: $font-11;
            letter-spacing: 0;
            line-height: $line-height18;
            margin-bottom: 0px;
        }

        .complete-text {
            color: $color-Electric-Purple;
            font-family: "public-sans";
            font-size: $font-11;
            letter-spacing: 0;
            line-height: $line-height18;
            margin-bottom: 0px;
        }

        .check-icon {
            width: 18px;
            height: 18px;
        }
    }
}

.sliderItem {
    .caption {
        color: $color-Violent-Violet;
        font-family: "public-sans";
        font-size: $font-15;
        font-weight: $font-medium;
        letter-spacing: 0;
        line-height: $line-height18;

        p {
            margin-bottom: 0px;
        }
    }
}

.sliderBox {
    padding-bottom: 45px;

    .slick-dots {
        bottom: -30px;

        li {
            margin: 0px 4px;
            width: 10px;
            height: 10px;
        }

        li.slick-active button:before {
            opacity: 1;
            color: rgba(24, 1, 72, 1);
        }

        li button:before {
            font-size: 12px;
            opacity: 0.34;
            color: rgba(24, 1, 72, 1);
        }
    }

    .slick-next {
        margin-top: -10px;
        transform: rotate(180deg);
        background: url("../../Images/back_arrow.svg") center center no-repeat !important;

        &::before {
            display: none;
        }
    }

    .slick-prev {
        background: url("../../Images/back_arrow.svg") center center no-repeat !important;

        &::before {
            display: none;
        }
    }
}

.tips-block {
    padding: 1.2rem;
    min-height: 165px;
    min-width: 290px;
    width: 100%;
    border-radius: 12.1px 3.63px 30.25px 3.63px;
    background-color: $color-White;
    box-shadow: 0 0 8px 3px rgba(4, 34, 107, 0.11);
}

@media (min-width: 576px) {
    .tips-block {
        width: 396px;
        min-height: 165px;
    }
}

.initial-postion {
    position: absolute;
    bottom: 0;
    right: 0;
}

.over-position {
    position: relative;
    z-index: 111;
}

.tips-button-size {
    height: 50px;
    width: 50px;
    background-color: transparent;
    padding: 0px;
    // background: linear-gradient(
    //   133.55deg,
    //   #0effff 0%,
    //   rgba(0, 107, 241, 0.71) 40.06%,
    //   #bf06ff 100%
    // );
    // border-radius: 50%;
}

.init-top-right {
    top: 5.8rem;
    right: 2.1rem;
}

.category-card {
    .cards-icon {
        margin-left: auto;
        margin-right: auto;
        width: 40px;
    }

    // .icon {
    //   width: 38px;
    //   margin-left: auto;
    //   margin-right: auto;
    // }
}

.incomplete-circle {
    width: 18px;
    height: 18px;
    border-radius: 50%;
    background-color: $color-Whisper;
}

.grid-2box {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 20px;
}

.fixed-lumen-help {
    bottom: 2.5rem;
    right: 4rem;
    z-index: 11;
}

// ================ component scss End ============

.lumenai-logo {
    width: 50px;
}

.desktop-menu {
    li {
        a {
            color: $color-White;
        }

        a[aria-current="page"] {
            color: #bf06ff;
        }
    }
}

.learning-header {
    .desktop-menu {
        li {
            a {
                color: $color-White;
            }

            a[aria-current="page"] {
                color: #1c004b;
            }
        }
    }
}

.summary-header {
    .navbar {
        background-color: #eef7ff;
    }

    .desktop-menu {
        li {
            a {
                color: $color-violet;
            }

            a[aria-current="page"] {
                color: #bf06ff;
            }
        }
    }
}

.share {
    .nav-height {
        padding-top: 2.5rem;
        height: 2.5rem;
    }
}

.mobile-footer-menu {
    position: fixed;
    bottom: 0px;
    z-index: 11;
    width: 100%;
    background-color: $color-Alice-Blue;

    .active {
        img.active-icon {
            display: block;
        }

        img.inactive-icon {
            display: none;
        }
    }

    a[aria-current="page"] {
        color: #006bf1;
    }

    .nav-link {
        padding: 0.5rem 0rem;

        .icon {
            width: 25px;
            height: 25px;
            margin-left: auto;
            margin-right: auto;
        }

        .active-icon {
            display: none;
        }
    }
}

// header end
.nav-height {
    height: 5.125rem;

    @media (max-width: 575px) {
        height: 4rem;
    }
}

.infinite-icon-for-mobile {
    max-width: 35px;
    display: block;
    margin-top: 8px;
}

.learning-header {
    .navbar {
        background-color: #0067ff;
    }
}

//================active-learning-screen ==========

.active-learning-screen {
    z-index: 1;
    position: relative;

    &::before {
        padding-bottom: 27%;
        z-index: -1;
        background-image: url("../../Images/desktop_Pathway_Curve.svg");

        @media (max-width: 575px) {
            padding-bottom: 50% !important;
            background-image: url("../../Images/Mobile_curve_dashboard.svg");
        }
    }
}

//================== landing screen ================

.lp-screen {
    overflow-x: hidden;

    .wrp-logo {
        .logos {
            width: 59px;
            margin-left: auto;
            margin-right: auto;
        }

        .title {
            color: $color-Violent-Violet;
            font-family: "public-sans";
            font-size: $font-25;
            font-weight: $font-medium;
            letter-spacing: 0;
            line-height: $line-height29;
        }
    }

    .lp-box {

        // margin-top: 4rem;
        .small-text {
            color: $color-Sapphire;
            font-family: "public-sans";
            font-size: $font-18;
            font-weight: $font-medium;
            letter-spacing: 0;
            line-height: $line-height18;
            margin-bottom: 0px;
        }

        .notice-text {
            color: $color-Violent-Violet;
            font-family: "public-sans";
            font-size: $font-15;
            font-weight: $font-medium;
            letter-spacing: 0;
            line-height: $line-height18;
            margin-bottom: 0px;
        }
    }
}

@media (min-width: 576px) {
    .logo-md {
        width: 42px;
    }
}

@media (min-width: 767px) {
    .jc-inside-space {
        padding: 1.25rem;
    }

    // .inside-space {
    //   padding: 32px 26px;
    // }


    .lp-card-size {
        width: 100%;
    }

    .jc-card-size {
        width: 327px;
    }
}

.btn-text {
    font-family: "public-sans";
    font-size: $font-16;
    font-weight: $font-bold;
    letter-spacing: 0;
    line-height: $line-height18;
    min-width: 178px;
}

.btn-gradiant-bg {
    background: linear-gradient(109deg,
            #0effff 0%,
            rgba(0, 107, 241, 0.71) 40.06%,
            #bf06ff 100%,
            #bf06ff 100%);
}

.moblie-size {
    max-width: 311px;
    margin-left: auto;
    margin-right: auto;
}

//=====================login screen

@media (min-width: 576px) {
    .mobile-view {
        background-color: transparent;

        &::before {
            content: none;
        }
    }
}

//====================== sign up screen

.signup-screen {
    .heading-with-logo {
        .logo {
            width: 41px;
        }
    }

    .breadcrumbs-layer {
        padding-top: 1rem;
        padding-bottom: 1rem;
        background-color: transparent;
        border: none;

        .lu_back {
            padding-left: 0px !important;
        }
    }
}

//========================= forget screen

.mobile-cards {
    padding-top: 24px;
    padding-bottom: 24px;
    padding-left: 24px;
    padding-right: 24px;
    border-top: 1px solid #dfe9f6;
    border-radius: 10px 3px 10px 3px;
    background-color: #ffffff;
    box-shadow: 0 0 6px 1px rgba(4, 34, 107, 0.11);

    .field-style {
        margin-bottom: 0px;
    }

    input[type="email"] {
        border: 1px solid #dcdcdc;
    }
}

.tips {
    font-size: 16px;
    font-weight: 500;
    font-family: "public-sans";
    line-height: 22px;
    color: #180148;
    letter-spacing: 0;
    margin-bottom: 24px;
}

.reset-btn {
    margin-top: 40px;
    border-radius: 25px;
    background-color: #180148;
    padding-left: 90px;
    padding-right: 90px;
    padding-top: 12px;
    padding-bottom: 12px;
    text-align: center;
}

//  ==========================initail soft skill

.category-box {
    .cards-title {
        h4 {
            margin-bottom: 0px;
        }
    }

    @media (min-width: 576px) {
        max-width: 342px;
    }

    @media (max-width: 575px) {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        grid-gap: 1rem;
        // max-width: 342px;
    }
}

.cards-wrp {
    padding: 0.8rem 1.5rem;
    border-radius: 10px 3px 10px 3px;
    background-color: #ffffff;
    box-shadow: 0 0 6px 3px rgba(4, 34, 107, 0.11);
}

.cards-size {
    padding: 0.5rem;
    border-radius: 10px 3px 10px 3px;
    background-color: #ffffff;
    box-shadow: 0 0 6px 3px rgba(4, 34, 107, 0.11);
    min-height: 110px;

    // .cards-title {
    //   min-height: 2.5rem;
    // }
    @media (min-width: 576px) {
        // height: 112px;
        width: 98px;
        align-items: center;
        height: 110px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        word-break: break-word;
    }
}

.skill-card {
    border-radius: 10px 3px 10px 3px;
    background-color: #ffffff;
    box-shadow: 0 0 6px 3px rgba(4, 34, 107, 0.11);
    padding: 1rem;
}

.popup-wave {
    position: relative;
    z-index: 1;

    &::before {
        content: "";
        position: absolute;
        bottom: -76px;
        left: 0px;
        right: 0px;
        background-image: url("../../Images/Desktop_curve.png");
        padding-bottom: 76px;
        background-repeat: no-repeat;
        transform: rotate(180deg);
        width: 100%;
    }
}

@media (max-width: 575px) {
    .profile-img {
        width: 64px;
        height: 64px;
    }

    .mob-text-view {
        .for-mob-text {
            font-size: $font-13;
        }
    }

    .font-mob-16 {
        font-size: $font-16;
        line-height: $line-height22;
    }
}

//========================== hub
// .recent-training-block{
//   .category-card:last-child{
//     background-color: $color-Navy-Blue !important;
//   }
// }

@media (max-width: 575px) {
    .hub-screen.before-curve::before {
        height: 310px;
    }
}

@media (min-width: 576px) and (max-width: 767px) {
    .hub-screen.before-curve::before {
        height: 350px;
    }
}

.viewall-w {
    width: 50px;
}

.hub-screen {
    .blank-data {
        margin-top: -100px;
    }

    @media (max-width: 575px) {
        .blank-data {
            margin-top: -60px;
        }

        .topspace-pie {
            margin-top: -120px;
            // position: relative;
            // top:-120px;
        }

        .block-pie {
            position: absolute;
            top: inherit;
            width: calc(100% - 170px);
            text-align: left;

            .direcation {
                text-align: right;
                margin-top: 35px;
                position: relative;
            }
        }

        .pie-position-block {
            .pie-graph-chart {
                margin-top: -10%;
                // max-width: 280px;
                margin-left: auto;
                // margin-right: -40%;
                margin-right: -142px;
            }
        }
    }

    @media (min-width: 576px) and (max-width: 767px) {
        .topspace-pie {
            margin-top: -220px;
        }

        .block-pie {
            position: absolute;
            top: initial;
            width: calc(100% - 280px);
            text-align: left;

            .direcation {
                text-align: right;
                margin-top: 20px;
                position: relative;
            }
        }

        .pie-position-block {
            .pie-graph-chart {
                // max-width: 300px;
                // max-width: 290px;
                margin-left: auto;
                // margin-right: -8%;
                margin-right: -4px;
            }
        }
    }

    @media (min-width: 768px) and (max-width: 992px) {
        .topspace-pie {
            margin-top: -228px;
        }

        .block-pie {
            position: absolute;
            top: 16px;
            width: calc(100% - 280px);
            text-align: right;

            .direcation {
                margin-top: 50px;
                position: relative;
            }
        }

        .pie-position-block {
            .pie-graph-chart {
                margin-left: auto;
                margin-right: -8%;
            }
        }
    }

    @media (min-width: 993px) and (max-width: 1199px) {
        .topspace-pie {
            margin-top: -280px;
        }

        .block-pie {
            position: absolute;
            top: 16px;
            width: calc(100% - 300px);
            text-align: right;

            .direcation {
                margin-top: 50px;
                position: relative;
            }
        }

        .pie-position-block {
            .pie-graph-chart {
                // max-width: 340px;
                margin-left: auto;
                margin-right: -8%;
            }
        }
    }

    @media (min-width: 1200px) {
        .topspace-pie {
            margin-top: -310px;
        }

        .block-pie {
            position: absolute;
            top: 16px;
            width: calc(100% - 260px);
            text-align: right;

            .direcation {
                margin-top: 50px;
                position: relative;
            }
        }

        .pie-position-block {
            .pie-graph-chart {
                // max-width: 350px;
                margin-left: auto;
                margin-right: -15%;
            }
        }
    }
}

.before-curve {
    position: relative;

    &::before {
        content: "";
        position: absolute;
        width: 100%;
        height: 340px;
        left: 0px;
        right: 0px;
        top: 0px;
        background-repeat: no-repeat;
        background-size: 100% 100%;
        background-image: url("../../Images/Desktop_curve_dashboard.svg");

        @media (max-width: 575px) {
            height: 221px;
            background-image: url("../../Images/Mobile_curve_dashboard.svg");
        }
    }
}

.text-block-position {
    position: relative;
    margin-left: -75%;
    width: 200px;
    top: 80px;
    text-align: right;
}

.direcation {
    &::before {
        content: "";
        position: absolute;
        padding-bottom: 16%;
        width: 130px;
        top: -20px;
        width: 60px;
        height: auto;
        right: -32px;

        background-image: url(../../Images/dotted_line.svg);
        background-repeat: no-repeat;
        background-size: 100%;
    }
}

.hub-screen {
    .width-70 {
        @media (min-width: 768px) {
            max-width: 70%;
        }
    }

    .print-name-block {
        position: relative;
        margin-left: -51%;
        margin-top: -2px;
        width: 184px;
    }

    .pie-position {
        right: -15%;
    }

    .position-init {
        position: relative;
        top: -4.5rem;

        @media (min-width: 786px) and (max-width: 992px) {
            top: -2.5rem;
        }
    }
}

.pie-graph-chart {
    // width: 374px;
    // height: 374px;
    // padding: 10px 12px;
    background-color: transparent;
    overflow: hidden;
}

.core-skill {
    h3.title {
        width: 220px;
    }
}

.active-block {
    display: grid;
    grid-gap: 1rem 1.25rem;

    @media (min-width: 768px) {
        grid-template-columns: 1fr 1fr;
    }
}

.learning-box {
    .width-48 {
        @media (min-width: 768px) {
            max-width: 48%;
        }
    }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
    .mob-tab {
        margin-top: 7rem;
    }
}

//Request access screen
.request-access {
    @media (max-width: 767px) {
        height: 100vh;
    }

    .heading-with-logo {
        .logo {
            width: 41px;
        }
    }

    .m-12 {
        margin-top: -2rem;
    }

    .breadcrumbs-layer {
        padding: 1rem;
        border: none;

        .lu_back {
            padding-left: 0px !important;
        }
    }

    .request-btn {
        @media (max-width: 575px) {
            width: 100%;
            display: block;
            max-width: 311px;
            margin: 2rem auto 1rem;
        }
    }
}

//pathway screen
@media (min-width: 768px) {
    .pathway_screen {
        .width-70 {
            max-width: 70%;
        }
    }
}

.hub {
    height: 18px;
    //width: 31px;
    color: #ffffff;
    font-family: Ubuntu;
    font-size: 16px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 18px;
    text-align: center;
}

.pathway {
    font-weight: 500;
    letter-spacing: 0;
}

// .progress-bar {
//   border-radius: 3px;
//   width: 505px;
//   height: 6px;
// }
// .add-radius3{
//   .bar1,.bar2,.bar3{
//       border-top-right-radius: 3px;
//       border-bottom-right-radius: 3px;
//   }
// }
// .bar1,.bar2,.bar3{
//   border-top-right-radius: 3px;
//   border-bottom-right-radius: 3px;
// }

.borderRadius3:last-child {
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px;
}

.done {
    //height: 63px;
    width: 243px;
    border-radius: 10px 3px 10px 3px;
    background-color: #ffffff;
    box-shadow: 0 0 6px 3px rgba(4, 34, 107, 0.11);
}

.p-10 {
    padding-top: 10rem;
}

//questions screen

.grid-box2 {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 50px;

    @media (max-width: 767px) {
        grid-template-columns: 1fr;
        grid-gap: 20px;
    }
}

// .question-card {
//     max-width: 515px;
// }

.question-box-size {
    max-width: 560px;
}

@media (min-width: 768px) {
    .box-question {
        .qcard-size {
            padding: 1.25rem 1.25rem;
        }
    }

    .wrp-box-question {
        position: relative;
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-gap: 32px;
        align-items: flex-start;

        .qcard-size {
            padding: 1.5rem 1.25rem;
        }
    }
}

.wrp-box-question {
    position: relative;
    z-index: 1;
}

// .questions-container{
//   // overflow-y: scroll;
//   // height: 100%;
// }

// .container{
//   justify-content: center;
//   display: inline;
// }

.bg-color {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;

    @media (min-width: 768px) {
        margin-top: 5.6rem;
    }
}

.bg-image {
    width: 250px;
    left: 150px;
    top: -60px;

    @media (min-width: 768px) {
        height: 435px;
        width: 435px;
        margin-top: 16%;
        margin-left: 50%;
    }
}

.question-lot-grp {
    border-radius: 3.6px;
    display: inline-block;
    background-color: $color-Navy-Blue;
    padding: 2px 4px;
}

// .question-lot-grp {
//   position: absolute;
//   border-radius: 3.6px;
//   background-color: #006bf1;
//   top: 3%;
//   right: 10%;
//   display: inline-block;
//   @media (min-width: 768px) {
//     top: 10%;
//     right: 40%;
//   }
// }

.ques-logo {
    width: 50%;
    float: left;
}

.ques-lot {
    color: #ffffff;
    font-family: Ubuntu;
    font-size: 18px;
    font-weight: bold;
    letter-spacing: 0;
    padding: 5px;
    left: 20%;
    line-height: 21px;
    display: inline-block;
}

// .close-lot{
//   @media (min-width: 768px) {
//     float: left;
//   }
// }

// .employability-assess {
//   // height: 48px;
//   width: 265px;
//   color: #04226b;
//   // font-family: Ubuntu;
//   font-size: 21px;
//   // font-weight: bold;
//   letter-spacing: 0;
//   line-height: 25px;
//   margin-top: -30px;
//   // margin-left: 10px;
//   margin-bottom: 20px;
//   // @media (min-width: 768px) {
//   //   margin-left: 20%;
//   //   width: 348px;
//   // }
// }

.oval {
    height: 306px;
    width: 306px;
    margin-top: 25px;
    margin-left: 20px;
    border-radius: 50%;
    align-content: center;
    background-color: #ffffff;
    box-shadow: 0 0 12px 7px rgba(4, 34, 107, 0.11);
}

.oval-quarter {
    height: 119.82px;
    width: 119.79px;
    margin-top: 25px;
    margin-left: 20px;
    border-radius: 50%;
    transform: scaleX(-1);
    background: linear-gradient(203.05deg,
            #0effff 0%,
            rgba(0, 107, 241, 0.71) 40.06%,
            #bf06ff 100%);
}

// .ques-card-hide{
//   @media (min-width: 768px) {
//     display: none;
//   }
// }

.question-card {
    width: 100%;
    position: relative;
    margin-bottom: 12px;
    // left: 5px;
    // width: 90%;
    // @media (min-width: 768px) {
    //   margin-left: 20%;
    //   display: none;
    //   padding: 10%;
    //   width: 4rem;
    //   min-height: 10%;
    //   overflow-y: auto;

    //   .cards-title-name{
    //     margin-left: 20%;
    //   }
    // }
    .question-card>img,
    .question-card>span {
        display: inline-block;
    }

    .icon {
        width: 38px;
        margin-left: auto;
        margin-right: auto;
    }

    // .question-text {
    //   color: #04226b;
    //   font-family: Ubuntu;
    //   font-size: 18px;
    //   font-weight: bold;
    // margin-left: 1rem;
    // }
    // .question-info {
    //   text-align: left;
    //   margin-left: 10px;
    //   margin-bottom: 10px;
    // }
    .icon-hint {
        width: 0.875rem;
        height: 0.875rem;
    }

    // .question-hint {
    //   height: 20px;
    //   width: 85px;
    //   color: #d2d2d2;
    //   font-family: Ubuntu;
    //   font-size: 13px;
    //   font-weight: 500;
    //   letter-spacing: 0;
    //   line-height: 20px;
    //   margin-left: 5px;
    // }
}

.answer-grp {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 1rem;
    // margin-top: 60px;
    align-content: center;

    // @media (min-width: 768px) {
    //   margin-top: 7%;
    //   margin-left: 14%;
    //   width: 50%;
    // }
    .answer-card {
        margin: auto;
        position: relative;
        display: inline-block;
        width: 80%;
        height: 80px;

        @media (min-width: 768px) {
            width: 50%;
        }

        // margin-left: 25px;
        // margin-right: 25px;

        .answer-text {
            height: 18px;
            text-align: center;
            width: 115.81px;
            color: #180148;
            font-family: Ubuntu;
            font-size: 16px;
            letter-spacing: 0;
            line-height: 18px;
        }
    }
}

.donut {
    height: 20%;
    width: 20%;
}

.qcard-size {
    padding: 0.8rem;
    border-radius: 10px 3px 10px 3px;
    background-color: #ffffff;

    // @media (min-width: 576px) {
    //   width: 40%;
    // }
    // .answer {
    //   padding: 4px 4px 18px 4px;
    //   margin: 12px;
    // }

    // .question-lot {
    //   height: 20px;
    //   width: "50%";
    //   float: right;
    //   color: #d2d2d2;
    //   font-family: Ubuntu;
    //   font-size: 15px;
    //   font-weight: 500;
    //   letter-spacing: 0;
    //   line-height: 20px;
    // }
    .divider-line {
        box-sizing: border-box;
        height: 1px;
        border: 1px solid #979797;
        opacity: 0.35;
    }
}

// .select-one-answer {
//   height: 20px;
//   width: "50%";
//   float: left;
//   margin-top: 4%;
//   // margin-top: 1rem;
//   margin-left: 9%;
//   margin-bottom: 1.4rem;
//   color: #04226B;
//   font-family: Ubuntu;
//   font-size: 15px;
//   font-weight: bold;
//   letter-spacing: 0;
//   line-height: 20px;
//   @media (min-width: 768px) {
//     text-align: center;
//     margin-top: 2%;
//     margin-left: 21%;
//   }
// }

// .answers{
//   margin-top: 3rem;
// }

.top-left-answer {
    text-align: left;
    margin-right: 16px;
    // width: 50%;
    // float: left;
    // color: #180148;
    // font-family: Ubuntu;
    // font-size: 16px;
    // letter-spacing: 0;
    // line-height: 18px;
}

.top-right-answer {
    text-align: right;
    // width: 50%;
    // float: right;
    // text-align: right;
    // color: #180148;
    // font-family: Ubuntu;
    // font-size: 16px;
    // letter-spacing: 0;
    // line-height: 18px;
}

.next-container {
    height: 20%;

    @media (min-width: 768px) {
        height: min-content;
    }
}

.prev-btn {
    width: 80%;
    float: left;
    display: block;
    margin: 0 0 1.2rem 0;

    @media (min-width: 768px) {
        width: 35%;
        margin: auto 3rem auto 3rem;
    }
}

.next-btn {
    width: 80%;
    float: left;
    display: block;
    margin: 0 0 1.2rem 0;

    @media (min-width: 768px) {
        width: 35%;
        margin: auto auto auto 3rem;
    }
}

.infinity-btn {
    width: 15%;
    float: right;

    // margin-top: 1.6rem;
    @media (min-width: 768px) {
        height: 60px;
        width: 60px;
        align-self: center;
        bottom: 1rem;
        right: 4rem;
        position: fixed;
    }
}

.oval-copy-159 {
    height: 108.84px;
    width: 108.81px;
    transform: scaleX(-1);
    background: "linear-gradient(203.05deg,#0effff 0%,rgba(0, 107, 241, 0.71) 40.06%,#bf06ff 100%);";
}

// .radio-btn {
//   color: #180148;
//   font-family: Ubuntu;
//   font-size: 16px;
//   letter-spacing: 0;
//   line-height: 18px;
//   margin-left: 1.2rem;
//   // margin-top: 1.5rem;
//   margin-bottom: 1.5rem;
//   justify-content: space-evenly;
//   width: 70%;
//   float: left;
// }

// .radio-btn-grp {
// .form-check-input {
//   bottom: 0.2rem;
// }
// .form-check-label {
//   margin-left:1.25rem;
// }
// }

.infinity-float {
    @media (min-width: 768px) {
        width: 100%;
        height: 45px;
        width: 45px;
    }
}

.icon-radio-hint {
    width: 14px;
    // height: 14px;
    // float: right;
    // width: 20%;
    // margin-top: 0.2rem;
    // margin-bottom: 1.5rem;
}

.icon-hint-ans {
    position: absolute;
    width: 23px;
    height: 23px;
    background: white;
    border-radius: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    top: -12px;
    text-align: right;
    right: -4px;

    img {
        width: 14px;
    }
}

.listings {
    margin-left: 34px;
    margin-right: 34px;

    ul {
        margin: 0 auto;
    }

    li {
        display: inline-block;
        vertical-align: top;
    }
}

.view-learn {
    box-sizing: border-box;
    height: 33px;
    width: 163px;
    border: 2px solid #04226b;
    border-radius: 25px;
}

.m-8 {
    margin-right: 1rem;
}

.nav {
    .navbar-brand {
        margin-right: 4rem;
    }
}

// ============================ pathway details

.background-icon-postion {
    overflow: hidden;
    position: absolute;
    width: 338px;
    height: 370px;
    // height: 427px;
    right: 50px;
    top: 30px;
    background-repeat: no-repeat;
    background-size: 100% 100%;

    @media (max-width: 767px) {
        width: 211px;
        height: 267px;
        top: -20px;
        right: 20px;
    }
}

//================================pathway

.before-curve-pathway {
    position: relative;

    &::before {
        content: "";
        position: absolute;
        width: 100%;
        height: auto;
        left: 0px;
        right: 0px;
        top: 0px;
        padding-bottom: 23%;
        background-repeat: no-repeat;
        background-size: 100% 100%;
    }
}

.before-curve-skill {
    position: relative;

    &::before {
        content: "";
        position: absolute;
        width: 100%;
        height: auto;
        left: 0px;
        right: 0px;
        top: 0px;
        padding-bottom: 35%;
        background-repeat: no-repeat;
        background-size: 100% 100%;
    }
}

.pathway-screen {
    &::before {
        padding-bottom: 46%;
        background-image: url("../../Images/desktop_Pathway_Curve.svg");

        @media (min-width: 1600px) {
            padding-bottom: 38%;
        }

        @media (min-width: 993px) and (max-width:1199px) {
            padding-bottom: 18%;
            background-image: url("../../Images/desktop_Pathway_Curve.svg"), linear-gradient(180deg, #1a0047 80%, #eef7ff 0%);
            background-position: bottom;
            background-size: 100% auto;
            height: 366px;
        }

        @media (max-width: 575px) {
            padding-bottom: 50%;
            background-image: url("../../Images/Mobile_curve_dashboard.svg"),
                linear-gradient(180deg, rgb(26, 0, 71) 75%, rgb(238, 247, 255) 0%);
            background-position: bottom;
            background-size: 100% auto;
            height: 510px;
        }
    }
}

.active-learning {
    .box-size {
        @media (min-width: 576px) {
            width: 19.5rem;
        }

        @media (min-width: 768px) {
            width: 8.5rem;
        }

        @media (min-width: 992px) {
            width: 11rem;
        }

        @media (min-width: 1200px) {
            width: 14rem;
        }

        @media (max-width: 575px) {
            width: 9.5rem;
        }
    }
}

.pathway-complete-screen {

    // @extend .pathway-screen;
    &::before {
        padding-bottom: 46%;
        background-image: url("../../Images/desktop_Pathway_Curve.svg");

        @media (min-width: 1600px) {
            padding-bottom: 38%;
        }

        @media (min-width: 993px) and (max-width:1199px) {
            padding-bottom: 18%;
            background-image: url("../../Images/desktop_Pathway_Curve.svg"), linear-gradient(180deg, #1a0047 80%, #eef7ff 0%);
            background-position: bottom;
            background-size: 100% auto;
        }

        @media (max-width: 575px) {
            padding-bottom: 50%;
            background-image: url("../../Images/Mobile_curve_dashboard.svg"),
                linear-gradient(180deg, rgb(26, 0, 71) 75%, rgb(238, 247, 255) 0%);
            background-position: bottom;
            background-size: 100% auto;
        }
    }


}

.initial-softskill {
    .initial-soft {
        z-index: 1;

        &::before {
            padding-bottom: 33%;
            z-index: -1;

            @media (min-width: 1600px) {
                padding-bottom: 26%;
            }

            @media (min-width: 1920px) {
                padding-bottom: 20%;
            }

            @media (max-width: 767px) {
                padding-bottom: 0px;
                bottom: -150px;
            }

            @media (min-width: 768px) and (max-width: 992px) {
                padding-bottom: 50%;
            }

            @media (min-width: 993px) and (max-width: 1199px) {
                padding-bottom: 40%;
            }
        }

        // @extend .pathway-screen;

        &::before {
            // padding-bottom: 46%;
            background-image: url("../../Images/desktop_Pathway_Curve.svg");

            @media (min-width: 1600px) {
                // padding-bottom: 38%;
            }

            @media (min-width: 993px) and (max-width:1199px) {
                // padding-bottom: 18%;
                // background-image: url("../../Images/desktop_Pathway_Curve.svg"), linear-gradient(180deg, #1a0047 80%, #eef7ff 0%);
                // background-position: bottom;
                // background-size: 100% auto;
            }

            @media (max-width: 575px) {
                padding-bottom: 50%;
                background-image: url("../../Images/Mobile_curve_dashboard.svg"),
                    linear-gradient(180deg, rgb(26, 0, 71) 50%, rgb(238, 247, 255) 0%);
                background-position: bottom;
                background-size: 100% auto;
                height: 260px;
            }
        }


    }
}

.pathway-screen {
    @media (min-width: 767px) {
        .width-70 {
            width: 70%;
        }
    }

    .view-learning-btn {
        border-color: $color-lightBlue;
        color: $color-lightBlue;
    }
}

// ======================== media query ===

@media (max-width: 575px) {
    .hub-screen {
        padding-bottom: 90px;
    }

    .cards-wrp {
        padding: 0.8rem 1rem;
    }

    .mb-70 {
        margin-bottom: 87px;
    }

    .fixed-lumen-help {
        bottom: 5.5rem;
        right: 1rem;
    }

    .mobile-w-size {
        width: 245px;
    }

    .mobile-btns-size {
        max-width: 311px;
        width: 100%;
        margin-left: auto;
        margin-right: auto;
    }

    .mobile-w-243 {
        width: 243px;
    }

    .mobile-middle-btn {
        display: block;
        width: 296px;
        margin: 2.5rem auto;
    }

    .join-company {
        .wrpfrom-mobile {
            padding: 14px 24px;
        }
    }

    .heading-with-logo {
        margin-top: 0.75rem;

        .subtitle {
            text-align: center;
        }
    }

    .mobile-wrpspace {
        padding: 0rem 1.8rem;
    }

    .company-cnf-media {
        text-align: center;

        .icon-company-cnf {
            margin-bottom: 1rem;
        }
    }

    .start_assessmet {
        text-align: center;
        margin-top: 1.5rem;

        button {
            width: 296px;
            margin-left: auto;
            margin-right: auto;
        }
    }

    .signup-screen {
        .mobile-view {
            .form-check {
                display: flex;
                align-items: center;
            }

            .tnc-style {
                label {
                    color: #ffffff;
                }
            }

            .align-box {
                text-align: center;
            }

            .register-btn {
                width: 296px;
                margin: 1rem auto;
                background: $color-Alice-Blue;
                color: #1c004b !important;
            }
        }
    }

    .join-company-cnf {
        .wrpfrom-mobile {
            padding: 10px 24px;
        }
    }

    .wrp-for-mobile {
        border-radius: 10px 3px 10px 3px;
        background-color: #ffffff;
        box-shadow: 0 0 6px 3px rgba(4, 34, 107, 0.11);
    }

    .assessment-inner-space {
        padding-top: 16px;
        overflow: hidden;
    }

    .padding-lr {
        padding-left: 18px !important;
        padding-right: 18px !important;
    }

    .content-footer {
        padding-top: 16px;
        padding-bottom: 16px;
        padding-left: 18px;
        padding-right: 18px;
    }

    .assesment-box {
        .top-heading {
            border-bottom: 1px solid #e7e7e7;
            padding: 0px 0px 16px;
            margin-bottom: 0px;
        }

        .bottom-content {
            padding-top: 16px;

            .card-content {
                padding-bottom: 16px;

                p:last-child {
                    margin-bottom: 1rem;
                }
            }
        }
    }

    .join-company-cnf,
    .join-company {
        .mobile-wrpspace {
            .join-btn {
                width: 100%;
            }
        }
    }

    .skill-category {
        width: 159px;
        margin-right: 1rem;
    }

    // .category-card{

    // }
    .category-card {
        width: 98px;
        margin-right: 1rem;
    }

    .category-box .category-card {
        width: auto;
        margin-right: 0rem;
        // max-width: 98px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        min-height: 110px;
    }

    .active-block {
        display: grid;
        grid-template-columns: 1fr;
        grid-gap: 1rem 1.25rem;
    }

    .faq-screen .grid-2box {
        display: grid;
        grid-template-columns: 1fr;
        grid-gap: 20px;
    }

    .request-access {
        .align-box {
            height: 15vh;
            display: flex;
            align-items: end;
            margin: 0 !important;
        }

        .align-box-change-password {
            height: 23vh;
            display: flex;
            align-items: end;
            margin: 0 !important;
        }
    }

    .faq-screen .cardfaq {
        border-radius: 10px 3px 10px 3px;
        box-shadow: 0 0 6px 3px rgba(4, 34, 107, 0.11);
    }

    .edit-profile {
        .request-btn {
            width: 100%;
            display: block;
            max-width: 311px;
            margin: 2rem auto 1rem;
        }
    }
}

@media (min-width: 576px) and (max-width: 767px) {
    .hub-screen {
        padding-bottom: 90px;
    }

    .mb-70 {
        margin-bottom: 87px;
    }

    .fixed-lumen-help {
        bottom: 5.5rem;
    }

    .join-company-cnf {
        .mobile-wrpspace {
            text-align: center;
            max-width: 320px;
            margin: auto;
        }
    }

    .mobile-wrpspace {

        // text-align: center;
        .start_assessmet {
            margin-top: 1rem;
        }

        .icon-company-cnf {
            margin-bottom: 1rem;
        }
    }

    .assessment-card {
        margin-right: auto;
        margin-left: auto;
    }

    .learning-history-screen,
    .pathway-complete-screen,
    .initial-soft {
        &::before {
            padding-bottom: 62%;
        }
    }



    .pathway-screen {
        &::before {
            background-image: url("../../Images/Mobile_curve_dashboard.svg"),
                linear-gradient(180deg, rgb(26, 0, 71) 65%, rgb(238, 247, 255) 0%);
            background-position: bottom;
            background-size: 100% auto;
            height: 440px;
        }
    }

    .wrp-for-mobile {
        border-radius: 10px 3px 10px 3px;
        background-color: #ffffff;
        box-shadow: 0 0 6px 3px rgba(4, 34, 107, 0.11);
    }

    .assessment-inner-space {
        padding-top: 16px;
        overflow: hidden;
    }

    .padding-lr {
        padding-left: 18px !important;
        padding-right: 18px !important;
    }

    .content-footer {
        padding-top: 16px;
        padding-bottom: 16px;
        padding-left: 18px;
        padding-right: 18px;
    }

    .assesment-box {
        .top-heading {
            border-bottom: 1px solid #e7e7e7;
            padding: 0px 0px 16px;
            margin-bottom: 0px;
        }

        .bottom-content {
            padding-top: 16px;

            .card-content {
                padding-bottom: 16px;

                p:last-child {
                    margin-bottom: 1rem;
                }
            }
        }
    }

    .summary-screens .position-initial {
        top: 130px;
    }

}

@media (max-width: 767px) {
    .wrpfrom-mobile {
        padding: 24px;
        border-radius: 0px 0px 10px 3px;
        background-color: $color-White;
        box-shadow: 0 5px 6px 1px rgba(4, 34, 107, 0.11);

        .subtitle {
            font-size: $font-16;
            margin-bottom: 1.2rem !important;
        }

        input {
            border: 1px solid #dcdcdc;
        }
    }

    .hub-screen {
        .mobile-wrp-100 {
            max-width: 100%;
        }
    }

    .overflow-mobile {
        overflow: hidden;
    }

    .pie-position:before {
        content: "";
        background: #180248;
        width: 100%;
        // height: 210px;
        height: 110px;
        display: block;
        position: absolute;
        left: 0px;
        right: 0px;
    }

    .hub-screen {
        .position-init {
            position: relative !important;
            right: -45%;
            top: -7.5rem !important;
            z-index: 1;
            width: 320px;
            height: 320px;
            padding: 4px 4px;
        }

        .pie-position {
            position: initial !important;
            right: 0px !important;
        }

        .print-name-block {
            margin: 0px !important;
            width: 180px;
            top: 20px;
        }

        .text-block-position {
            margin: 0px;
            text-align: left;
            width: 142px;
            top: 50px;

            .text-block1 {
                font-size: 13px;
                text-align: center;
            }

            .text-block2 {
                font-size: 11px;
                text-align: center;
            }
        }
    }

    // .before-curve::before {
    //   // margin-top: 150px;
    //   // margin-top: 30px;
    // }
    .recent-training {
        // margin-top: -240px;
        z-index: 1;
        position: relative;
    }

    .employability-assess {
        width: 290px;
    }

    .summary-screens .position-initial {
        position: absolute;
        // top: 27.5%;
        top: 196px;
        left: 0;
        right: 0;
        max-width: 90%;
        margin: auto;
    }

    .what-next,
    .what-view-all {
        color: #0067ff;
    }
}


.initial-summary-screens {
    position: relative;
}

@media (min-width: 768px) and (max-width: 992px) {


    .summary-screens .position-initial {
        top: 0px;
        right: 32px;
    }


    .pie-graph-chart {
        // max-width: 310px;
        // height: 310px;
        // padding: 2px;
    }

    .text-block-position {
        top: 60px;
        margin-left: -54%;
        width: 166px;
    }

    .hub-screen {
        .pie-position {
            right: -3rem;
        }
    }

    .pathway-screen.before-curve-pathway::before {
        padding-bottom: 52%;
        // background-size: cover;
        background-image: url("../../Images/Mobile_curve_dashboard.svg"),
            linear-gradient(180deg, rgb(26, 0, 71) 70%, rgb(238, 247, 255) 0%);
        background-position: bottom;
        background-size: 100% auto;
        height: 350px;

    }

    .before-curve-pathway::before {
        padding-bottom: 27%;
    }
}

@media (min-width: 576px) and (max-width: 767px) {
    .for-mobile {
        margin-top: 3rem;
    }
}

//change password screen
.blue {
    background-color: #eef7ff;
}

.cards-policy {
    border-radius: 16px 6px 16px 6px;
    background-color: $color-White;
    box-shadow: 0 0 11px 6px rgba(4, 34, 107, 0.11);
}

//Path-complete
.text-purple {
    color: #bf06ff;
}

@media only screen and (max-width: 600px) {
    .pathway-text {
        //height: 23px;
        //width: 127px;
        //color: #EEF7FF;
        font-family: Ubuntu;
        font-size: 20px;
        font-weight: 500;
        letter-spacing: 0;
        line-height: 24px;
    }
}

//learning history

.learning-history-screen {
    &::before {
        background-image: url("../../Images/desktop_learning_history_Curve.svg");

        @media (max-width: 575px) {
            background-image: url("../../Images/Mobile_curve_blue.svg");
            padding-bottom: 50%;
        }
    }
}

.searchbar {
    .search-field {
        max-width: 533px;
        height: 64px;
        width: 100%;

        padding-left: 2.8rem;
        padding-right: 1rem;
        background-image: url("../../Images/search.svg");
        background-size: contain;
        background-repeat: no-repeat;
        outline: 0;
        background-position: 18px;
        background-size: 18px;
    }
}

.see {
    border: none;
    border-radius: 10px 3px 10px 3px;
    background-color: #ffffff;
    box-shadow: 0 0 6px 3px rgba(4, 34, 107, 0.11);
}

.searchbar {
    input[type="search"] {
        font-size: 16px;
        font-family: ubuntu;
        color: black;
    }

    ::placeholder {
        color: #000000;
        font-family: Ubuntu;
        font-size: 16px;
        font-weight: bold;
        letter-spacing: 0;
        line-height: 18px;
    }
}

//pathway curve
// .up-wave {
//   position: relative;
//   z-index: 1;
//   &::before {
//     content: "";
//     position: absolute;
//     bottom: -76px;
//     left: 0px;
//     right: 0px;
//     background-image: url("../../Images/desktop_Pathway_Curve.svg");
//     padding-bottom: 76px;
//     background-repeat: no-repeat;
//     transform: rotate(180deg);
//     width: 100%;
//   }
// }

//settings
.set {
    max-width: 515px;
    min-width: 327px;
    border: none;

    border-radius: 10px 3px 10px 3px;
    background-color: #ffffff;
    box-shadow: 0 0 6px 3px rgba(4, 34, 107, 0.11);
}

//settings screen
.right-arrow {
    transform: rotate(180deg);
}

///contact us

select.contact-select {
    -moz-appearance: none;
    /* Firefox */
    -webkit-appearance: none;
    /* Safari and Chrome */
    appearance: none;
    background-image: url("../../Images/chevron_down.svg");
    background-repeat: no-repeat;
    background-position-x: 96%;
    background-position-y: 17px;
}

.field-style1 {
    label {
        color: $color-black;
        font-family: "public-sans";
        font-size: $font-13;
        font-weight: $font-medium;
        letter-spacing: 0;
        line-height: $line-height18;
        margin-bottom: 5px;
    }

    select {
        color: $color-black;
        font-family: "public-sans";
        font-size: $font-13;
        font-weight: $font-medium;
        height: 48px;
        width: 100%;
        border: 1px solid $color-Violent-Violet;
        border-radius: 3px;
        background-color: transparent;
    }

    textarea {
        color: $color-black;
        font-family: "public-sans";
        font-size: $font-13;
        font-weight: $font-medium;
        height: 392px;
        width: 100%;
        border: 1px solid $color-Violent-Violet;
        border-radius: 3px;
        background: transparent;
    }

    @media (min-width: 576px) {
        textarea {
            height: 280px;
        }
    }

    option {
        color: $color-black;
        font-family: "public-sans";
        font-size: $font-13;
        font-weight: $font-medium;
        height: 48px;
        width: 100%;
        border: 1px solid $color-Violent-Violet;
        border-radius: 3px;
        background: transparent;
    }
}

.optional {
    color: $color-black;

    font-weight: 300;
    letter-spacing: 0;
    line-height: 12px;
}

//Summary Page
// .summary-container{
//   width: 70%;
//   margin: auto;
//   .back-text{
//     height: 18px;
//   width: 32px;
//   color: #1C004B;
//   font-family: Ubuntu;
//   font-size: 14px;
//   font-weight: bold;
//   letter-spacing: 0;
//   line-height: 18px;
//   }
// .congrats-text{
// height: 23px;
// width: 262px;
// color: #180148;
// font-family: Ubuntu;
// font-size: 20px;
// font-weight: 500;
// letter-spacing: 0;
// line-height: 24px;
// display: block;
// @media (max-width: 480px) {
//   text-align: center;
// display: inline-block;

// }
// }
// .congrats-desc-text{
//   height: 20px;
//   color: #1C004B;
//   font-family: Ubuntu;
//   font-size: 16px;
//   font-weight: 500;
//   letter-spacing: 0;
//   line-height: 20px;
//   display: block;
//   @media (max-width: 480px) {
//     text-align: center;
//   display: inline-block;

//   }
// }
//   .infinity-mobile-only{
//     display: none;
//     @media only screen and (max-width: 480px){
//       display: inline-block;
//       padding: 1rem 1rem 1rem 6rem;
//     }
//   }
// }

.position-initial {
    position: absolute;
    top: 16px;
    right: 120px;
}

.congratulation-card {
    .assessment-card {
        background-color: $color-Electric-Purple;
    }

    .title {
        color: $color-White;
    }

    .sub-category {
        color: $color-White;
    }
}

.assessment-card {
    border-radius: 10px 3px 10px 3px;
    background-color: $color-White;
    box-shadow: 0 0 6px 3px rgba(4, 34, 107, 0.11);

    @media (min-width: 576px) {
        width: 327px;
    }

    .card-icon {
        width: 30px;
    }

    .contents {
        .title {
            // width: 120px;
            // color: $color-black;
            font-family: "public-sans";
            font-size: $font-16;
            font-weight: $font-bold;
            letter-spacing: 0;
            line-height: $line-height18;
        }

        .category {
            opacity: 0.6;
            color: $color-Grey;
            font-family: "public-sans";
            font-size: $font-11;
            letter-spacing: 0;
            line-height: $line-height18;
            margin-bottom: 0px;
        }

        .complete-text {
            color: $color-Electric-Purple;
            font-family: "public-sans";
            font-size: $font-11;
            letter-spacing: 0;
            line-height: $line-height18;
            margin-bottom: 0px;
        }
    }
}

.progress-card {
    // height: 73px;
    // width: 350px;
    // top: 25%;
    // left:5%;
    position: relative;

    // border-radius: 10px 3px 10px 3px;
    // background-color: transparent;
    // box-shadow: 0 0 6px 3px rgba(4, 34, 107, 0.11);
    // @media (min-width: 576px) {
    //   top: 0;
    //   float: left;
    //   left: 20%;
    // }
    .card-icon {
        width: 25px;
    }

    .content {

        // float: right;
        @media (min-width: 576px) {
            // margin-left: 50px;
        }

        .title {
            // bottom: 10px;
            color: $color-Alice-Blue;
            font-family: "public-sans";
            font-size: $font-16;
            font-weight: $font-bold;
            letter-spacing: 0;
            line-height: $line-height18;
            margin-left: 17px;
            display: inline-block;
            // @media (min-width: 576px) {
            //   margin-left: 12px;
            //   margin-right: 10px;
            // }
        }

        .percents {
            letter-spacing: 0.85px;
            color: $color-White;
            text-align: right !important;
        }
    }

    .progressbar {
        // width: 300px;
        height: 5px;
        margin-top: 10px;
        background-color: #381474;
    }
}

.wave-wrap {
    @media (min-width: 576px) {
        padding-top: 6rem;
        width: 70%;
        display: flex;
        margin: auto;

        .left-wrap {
            width: 30%;

            .category-progress {
                @media (max-width: 480px) {
                    display: none;
                }

                @media (min-width: 576px) {
                    display: inline;
                    margin-right: 4rem;
                }
            }
        }

        .right-wrap {
            float: right;

            @media (max-width: 480px) {
                justify-content: center;
                float: none;
            }
        }
    }
}

//summary
.summary-icon {
    height: 28.5px;
    width: 25.5px;
}

@media (max-width: 576px) {
    .sum-mobile {
        text-align: center;
    }
}

.emp-summary {
    border-radius: 16px 6px 16px 6px;
    background-color: #ffffff;
    box-shadow: 0 0 6px 3px rgba(4, 34, 107, 0.11);
    padding-left: 18px !important;
    padding-right: 18px !important;
}

.active-learning-summary {
    height: 73px;
    max-width: 327px;
    border-radius: 10px 3px 10px 3px;
    background-color: $color-White;
    box-shadow: 0 0 6px 3px rgba(4, 34, 107, 0.11);

    @media (max-width: 575px) {
        width: 100%;
    }

    .card-icon {
        width: 30px;
    }

    .contents {
        // .inner-box1 {
        //   max-width: 166px;
        //   width: 100%;
        // }

        .title {
            // width: 120px;
            color: $color-black;
            font-family: "public-sans";
            font-size: $font-16;
            font-weight: $font-bold;
            letter-spacing: 0;
            line-height: $line-height18;
        }

        .category {
            opacity: 0.6;
            color: $color-Grey;
            font-family: "public-sans";
            font-size: $font-11;
            letter-spacing: 0;
            line-height: $line-height18;
            margin-bottom: 0px;
        }

        .complete-text {
            color: $color-Electric-Purple;
            font-family: "public-sans";
            font-size: $font-11;
            letter-spacing: 0;
            line-height: $line-height18;
            margin-bottom: 0px;
        }

        .check-icon {
            width: 18px;
            height: 18px;
            //border-radius: 50%;
        }
    }
}

.active-summary {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 1rem 1.25rem;
}

.learn-per {
    color: #eef7ff;
    font-family: Ubuntu;
    font-size: 13px;
    font-weight: bold;
    letter-spacing: 0.69px;
    line-height: 17px;
    text-align: right;
    border-radius: 13.5px;
    background-color: #381474;
}

@media (min-width: 576px) {
    .summary-position {
        top: 6%;
        left: 73%;
    }
}

.summary-position {
    position: absolute;
    top: -147px;
    right: 30px;
}

.title-font {
    color: #eef7ff;
    font-weight: bold;
    letter-spacing: 0.85px;
    line-height: 22px;
}

//me

@media (min-width: 768px) {
    .set-position {
        position: absolute;
        top: -70px;
        right: -10%;
    }

    .me-screens {
        .set-position {
            top: -99px;
        }
    }
}

//company
.lumenai-com-uniquecode {
    background: linear-gradient(133.55deg,
            #0effff 0%,
            rgba(0, 107, 241, 0.71) 40.06%,
            #bf06ff 100%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.lumen {
    color: #eef7ff;
}

//soft skill explained ==================================

.soft-skill-curve {
    z-index: 1;

    &::before {
        background-image: url("../../Images/desktop_Pathway_Curve.svg");
        padding-bottom: 21rem;
        z-index: -1;

        @media (min-width: 768px) and (max-width: 992px) {
            padding-bottom: 23rem;
        }

        @media (min-width: 576px) and (max-width: 767px) {
            padding-bottom: 14rem;
            background-size: cover;
        }

        @media (max-width: 575px) {
            background-image: url("../../Images/Mobile_curve_dashboard.svg");
            padding-bottom: 12rem;

            background-size: 100% auto;
            /* height: 180px; */
            background-position: bottom center;
        }
    }
}

.max-size-503 {
    max-width: 503px;
}

.icon-w-30 {
    width: 30px;
}

.soft-active-block {
    display: grid;
    grid-gap: 1rem 3rem;
    grid-template-columns: 1fr;

    @media (min-width: 768px) {
        grid-template-columns: 1fr 1fr;
    }
}

.skill-block {
    display: grid;
    grid-gap: 1rem 1.25rem;
    grid-template-columns: 243px 243px;

    @media (max-width: 575px) {
        grid-template-columns: 1fr;
    }
}

.select-a-soft {
    color: #180148;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 23px;
}

.select-skill {
    color: #ffffff;

    letter-spacing: 0;
    line-height: 23px;
}

.skills-card-icon {
    width: 30px;
}

.share-my-result {
    .navbar {
        background-color: #eef7ff;
    }
}

.user_logo {
    width: 47px;
    height: 47px;
    border-radius: 50%;
}

.summary-screens {
    .active-learning {
        margin-bottom: 16px;
    }
}

@media (max-width: 575px) {
    .width-50 {
        width: 49%;
        margin-top: -20px;
    }
}

@media (max-width: 575px) {
    .line {
        &::before {
            content: "";
            box-sizing: border-box;
            height: 1px;
            width: 100%;
            border: 1px solid #ffffff;
            opacity: 0.22;
            position: absolute;
            left: 0%;
            right: 0%;
            margin-top: -1.5rem;
        }
    }
}

.width-percent {
    width: 75%;
}

.gray-block {
    pointer-events: none;
    // opacity: 0.6;
    background-color: darkgray;
    box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.71);
}

.category-progress-summary.category__block2 {

    .learnbtns,
    .percents {
        // display: none;
    }
}

.ovcer {
    position: relative;
}

.ovcer:before {
    content: '';
    width: 100%;
    height: 100vh;
    background-color: #000000a1;
    position: absolute;
    left: 0;
    background-image: url('../../Images/TutorialOverlay.svg');
}




.overlay_cirlce {
    z-index: 11;
    --circle-radius: 8%;
    /* changed as percent value */

    height: 100%;
    width: 100%;
    // --mask: radial-gradient(circle farthest-side at 17% 30%, transparent var(--circle-radius), #000 calc(var(--circle-radius) + 2px) 100%) 50% 50%/100% 100% no-repeat;
    -webkit-mask: var(--mask);
    mask: var(--mask);
    background-color: rgba(0, 0, 0, .5);
    background-image: url('../../Images/TutorialOverlay.svg');
    background-size: 230px;
    position: fixed;
    overflow: hidden;
    left: 0px;
    top: 0;
    bottom: 0px;

    @media (max-width:767px) {
        --circle-radius: 13%;
    }
}

.increase-zindex {
    z-index: 111;
}


.circle__Postion2 {
    --circlePostion: 25% 44%;

    @media (min-width:576px) and (max-width:767px) {
        --circlePostion: 16% 230px;
    }

    @media (max-width:575px) {
        --circlePostion: 13.5% 311px;
    }

    @media (min-width:768px) and (max-width:992px) {
        --circlePostion: 13.5% 315px;
        --circle-radius: 10%;
        /* changed as percent value */
    }

    @media (min-width:993px) and (max-width:1199px) {
        --circlePostion: 22.5% 300px;
    }

    @media (min-width:1400px) {
        --circlePostion: 30% 38%;
    }

    @media (min-width:1600px) {
        --circlePostion: 35% 44%;
    }

    --mask: radial-gradient(circle farthest-side at var(--circlePostion), transparent var(--circle-radius), #000 calc(var(--circle-radius) + 2px) 100%) 50% 50%/100% 100% no-repeat;
    -webkit-mask: var(--mask);
    mask: var(--mask);
}

.circle__Postion1 {
    --circlePostion: 18.5% 35%;
    --mask: radial-gradient(circle farthest-side at var(--circlePostion), transparent var(--circle-radius), #000 calc(var(--circle-radius) + 2px) 100%) 50% 50%/100% 100% no-repeat;
    -webkit-mask: var(--mask);
    mask: var(--mask);

    @media (max-width:767px) {
        --circlePostion: 21% 164px;
    }

    @media (min-width:768px) and (max-width:992px) {
        --circlePostion: 14% 254px;
        --circle-radius: 10%;
        /* changed as percent value */
    }

    @media (min-width:1400px) {
        --circlePostion: 23% 33.5%;
    }

    @media (min-width:1600px) {
        --circlePostion: 35% 34%;
    }

}

@media (max-width:767px) {
    .mescreen {

        .showlist__position,
        .guidelist__position {
            bottom: 135px;
        }

        .lumenreport__sticky {
            bottom: 4.5rem;
        }

        .key__fixed {
            position: absolute;
        }

    }


    .initial-summary-screens {
        position: relative;
    }

}

.summary-screens,
.initial-softskill {
    position: relative;
}

.me-header {
    .lumenheader {
        padding-bottom: 3rem !important;
    }
}



.form-group {
    position: relative;
}

.welcome {
    max-width: 681px;
    padding: 213px 0 32px;
    width: 47.292%;
    position: relative;
    opacity: 1;
    background-image: linear-gradient(rgba(0, 107, 241, 0.714571) 40%, rgba(191, 6, 255, 1) 110%, rgba(191, 6, 255, 1) 100%);
    ;

    &.single-data {
        width: auto;
        padding: 84px 0 28px;
        flex-basis: 95%;

        @media (max-width: 992px) {
            justify-content: flex-start;
            padding: 32px 0;
        }

        .welcome-content-container {
            flex-basis: 55%;

            p {
                width: 100%;
            }
        }
    }

    @media (max-width: 995px) {
        flex-basis: 100%;
        display: flex;
        padding: 40px 0 56px;
        justify-content: space-between;
        max-width: 100%;
        width: 100%;
        opacity: 1;
        background-image: linear-gradient(rgba(14, 255, 255, 1) 0%, rgba(0, 107, 241, 0.714571) 40%, rgba(191, 6, 255, 1) 100%, rgba(191, 6, 255, 1) 100%);
        min-height: 300px;
        justify-content: flex-end;
    }

    h1 {
        opacity: 1;
        color: rgba(255, 255, 255, 1);
        font-family: "public-sans";
        font-size: 48px;
        font-weight: 700;
        font-style: normal;
        letter-spacing: 0px;
        text-align: left;
        margin-bottom: 32px;

        @media (max-width: 992px) {
            font-size: 32px;
            margin-bottom: 16px;
        }
    }

    p {
        color: #fff;
        width: 86%;
        font-family: "public-sans";
        opacity: 1;
        color: rgba(255, 255, 255, 1);
        font-size: 24px;
        font-weight: 400;
        font-style: normal;
        letter-spacing: 0px;
        text-align: left;
        margin-bottom: 29px;

        @media (max-width: 992px) {
            font-size: 18px;
        }
    }

    figure {
        margin-top: 130px;

        @media (max-width: 992px) {
            margin-top: 10px;
            position: absolute;
            right: 0;
            width: 25%;
            top: 50%;
            transform: translateY(-50%);
            margin: 0;
        }

        @media (max-width: 767px) {
            width: 30%;
        }

        @media (max-width: 550px) {
            width: 45%;
        }


        img {
            width: 80%;

            @media (max-width: 992px) {
                width: 100%;
                transform: rotate3d(0, 58, 0, 180deg);
            }
        }

    }

    .welcome-content-container {
        padding-left: 79px;
        width: 61%;

        @media (max-width: 992px) {
            flex-basis: 55%;
            padding-left: 20px;
            width: 67%;

        }

    }

    .slick-dots {
        width: 100%;
        display: flex !important;
        justify-content: flex-start;

        li {
            margin: 0 3.8px;
        }

        button::before {
            content: "";
            height: 11px;
            width: 11px;
            background-color: #fff;
            font-size: 11px;
            border-radius: 0%;
        }
    }

}

.heading-with-logo {

    h3 {
        opacity: 1;
        color: rgba(55, 55, 55, 1);
        font-family: "public-sans";
        font-size: 32px;
        font-weight: 700;
        font-style: normal;
        letter-spacing: 0px;
        text-align: left;
        line-height: 32px;
    }

    p {
        opacity: 1;
        color: rgba(143, 143, 143, 1);
        font-family: "public-sans";
        font-size: 21px;
        font-weight: 400;
        font-style: normal;
        letter-spacing: 0px;
        text-align: left;
        line-height: 32px;
    }
}


.space-setup {
    .drag-drop-field {
        label:not(.company-logo) {
            display: block;
            width: 100%;
            height: 183px;
            background-color: #fff;
            position: relative;
            border: 1px dashed;
            border-radius: 4px;

            .drag-drop-container {
                width: 80%;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                word-break: break-word;
                text-align: center;

                span {
                    color: #006BF1;
                    font-weight: bold;
                }

                .drag-text {
                    opacity: 1;
                    color: rgba(55, 55, 55, 1);
                    font-family: "public-sans";
                    // font-size: 24px;
                    font-weight: 700;
                    font-style: normal;
                    letter-spacing: 0px;
                    text-align: left;
                    // line-height: 32px;
                    margin: 0;
                    text-align: center;

                }

                .support {
                    opacity: 1;
                    color: rgba(80, 80, 80, 1);
                    font-family: "public-sans";
                    // font-size: 21px;
                    font-weight: 400;
                    font-style: normal;
                    letter-spacing: 0px;
                    text-align: left;
                    // line-height: 32px;
                    text-align: center;
                }
            }
        }
    }
}

#lumenai {
    .group-card-container {
        background-color: rgba(0, 0, 0, .5);
        // display: none;

        .group-cards {
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            background-color: #fff;
            width: 328px;
            border-radius: 6px;

            .groups-list-data {
                max-height: 350px;
                overflow-y: scroll;
            }

            .title-container {
                // background-color: #180148;
                background: url("../../Images/popup_back.svg") no-repeat center/cover;
                padding: 47px 0 101px;
            }

            h3 {
                max-width: 279px;
                width: 60%;
                text-align: center;
                margin: 0 auto;
                color: rgba(255, 255, 255, 1);
                font-family: "public-sans";
                font-size: 18px;
                font-weight: 700;
                font-style: normal;
                letter-spacing: 0px;
                text-align: center;
                line-height: 23px;
            }

            ul {
                padding: 0;
                list-style-type: none;
                padding: 20px;


                li {
                    margin: 20px 0;
                    padding: 0 10px;
                    position: relative;

                    &:not(:last-child) {
                        border-bottom: 1px solid #000;
                    }

                    &::before {
                        content: "";
                        width: 8px;
                        height: 12px;
                        font-size: 18px;
                        display: inline-block;
                        position: absolute;
                        right: 10px;
                        top: 50%;
                        transform: translateY(-50%);
                        background: url("../../Images/chevron.svg") no-repeat center/cover;
                    }

                    h4 {
                        opacity: 1;
                        color: rgba(55, 55, 55, 1);
                        font-family: "public-sans";
                        font-size: 17px;
                        font-weight: 700;
                        font-style: normal;
                        letter-spacing: 0px;
                        text-align: left;
                    }

                    p {
                        opacity: 1;
                        color: rgba(80, 80, 80, 1);
                        font-family: "public-sans";
                        font-size: 16px;
                        font-weight: 400;
                        font-style: normal;
                        letter-spacing: 0px;
                        text-align: left;
                        line-height: 32px;
                    }
                }
            }
        }
    }
}

.desktop-menu {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: nowrap;
    width: 90%;
    margin-left: 0 !important;

    span.group-name {
        color: #fff;
        font-family: "public-sans";
        font-weight: 700;
        float: right;
        font-size: 16px;

        @media (max-width: 992px) {
            max-width: 250px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;

        }
    }

    select {
        float: right;
        background-color: transparent;
        border: 0;
        outline: none;
        color: #fff;
        font-family: "public-sans";
        font-weight: 700;
        float: right;
        font-size: 16px;
        max-width: 250px;
        overflow: hidden;
        text-overflow: ellipsis;

        @media (max-width: 767px) {
            display: none;
        }
    }
}

form {
    select {
        width: 100%;
        height: 48px;
        padding: 10px;
        border: 0;
        outline: 0;
        font-family: "public-sans";
        font-size: 13px;
        color: #505050;
        font-weight: 500;

        option {
            font-family: "public-sans";
            font-size: 13px;
            color: #505050;
            font-weight: 500;
        }
    }
}

.tags-input {
    .form-control {
        border: 0;

        @media (max-width: 992px) {
            height: 62px;
        }

        @media (max-width: 767px) {
            flex: 1 1 100%;
        }


        button {
            background-color: #fff;
        }
    }
}

.container {
    &.admin-form-container {
        margin-top: 92px;
    }
}

.admin-form-container {
    .cards-box {
        // border-radius: 16px 6px 16px 6px;
        // background-color: $color-White;
        // box-shadow: 0 0 11px 6px rgba(4, 34, 107, 0.11);

        .form-group {
            position: relative;
        }

        .show-password {
            display: inline-block;
            position: absolute;
            top: 51px;
            right: 0;
            transform: translateX(-50%);

            @media (max-width: 992px) {
                top: 58px;
            }
        }
    }




}

.people-manager {
    padding: 41px 0 133px;
    position: relative;

    .show {
        display: block;
    }

    .hide {
        display: none;
    }

    .disbaled-opacity {
        opacity: .7;
    }

    .container {
        width: 87.5%;
        max-width: 1440px;
        margin: 0 auto;
        padding: 0;

        @media (max-width: 992px) {
            padding-left: 20px;
            padding-right: 20px;
        }


        .manager-header {
            h3 {
                opacity: 1;
                color: rgba(55, 55, 55, 1);
                font-family: "public-sans";
                font-size: 32px;
                font-weight: 700;
                font-style: normal;
                letter-spacing: 0px;
                text-align: left;
                line-height: 32px;
            }

            form {
                position: relative;

                &::after {
                    content: "";
                    width: 18px;
                    height: 18px;
                    display: inline-block;
                    background: url("../../Images/search.svg") no-repeat center/cover;
                    position: absolute;
                    left: 14px;
                    top: 50%;
                    transform: translateY(-50%);
                }
            }

            input {
                width: 100%;
                border: 0;
                outline: 0;
                height: 48px;
                padding: 15px 15px 15px 40px;
                font-family: 'public-sans';


            }
        }

        .search {
            border-radius: 10px 3px 10px 3px;
            box-shadow: 0px 0px 6px 3px rgba(4, 34, 107, 0.113227);
            opacity: 1;
            background-color: rgba(255, 255, 255, 1);
        }

        button {
            min-width: unset;
        }

        .people-content {
            &.dotted {
                border: 1px dashed;

                p {
                    color: #8f8f8f;
                    max-width: 225px;
                    opacity: 1;
                    font-family: "public-sans";
                    font-size: 18px;
                    font-weight: 400;
                    text-align: center;
                    line-height: 26px;

                    @media (max-width: 995px) {
                        margin: 0 auto 1rem;
                    }
                }

                a {
                    @media (max-width: 767px) {
                        max-width: 250px;
                    }
                }

            }

            .content {

                text-align: center;
                height: 624px;


                div {
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                }
            }

            .pagination {
                li {
                    background-color: transparent;
                    padding: 0;

                    span {
                        display: none;
                    }

                    button {
                        opacity: 1;
                        color: #000;
                        font-family: "public-sans";
                        font-size: 16px;
                        font-weight: 400;
                        text-align: center;
                        line-height: 23px;

                        &.active {
                            color: #006BF1;
                        }


                    }
                }
            }

            .inactive {
                color: #006BF1;
            }
        }


        .people-manage-notice {

            position: relative;

            &:first-of-type {
                @media (max-width: 992px) {
                    &::after {
                        content: "";
                        width: 11px;
                        height: 7px;
                        display: inline-block;
                        background: url("../../Images/chevron_down.svg") no-repeat center/cover;
                        position: absolute;
                        right: 20px;
                        top: 27px;
                        pointer-events: none;
                    }
                }
            }



            &.tags-container {
                margin-top: 36px;

                @media (max-width: 992px) {
                    margin-top: 16px;
                }

                &>div {
                    margin-bottom: 30px;

                    @media (max-width: 992px) {
                        margin-bottom: 0;
                    }
                }

                .manage-card {
                    color: rgba(80, 80, 80, 1);
                    opacity: 1;
                    background-color: rgba(255, 255, 255, 0.6);
                    text-align: center;
                    border: 1px dashed;
                }

                h3 {
                    margin-bottom: 0;
                }

                .tags-create {
                    color: #0067ff;
                    margin-bottom: 19px;
                    opacity: 1;
                    color: rgba(0, 107, 241, 1);
                    font-family: "public-sans";
                    font-size: 18.9px;
                    font-weight: 700;
                    font-style: normal;
                    letter-spacing: 0px;
                    text-align: center;
                    line-height: 28.8px;
                }

                .tag-list-container {
                    list-style-type: none;
                    padding-left: 0;

                    .action-container {
                        display: flex;
                        align-items: center;
                        position: absolute;
                        right: 10px;

                        span {
                            white-space: nowrap;
                        }
                    }

                    .show {
                        position: absolute;
                        width: 100%;
                        right: 5px;
                        top: 5px;
                    }

                    li {
                        padding: 22px;
                        position: relative;
                        background-color: #fff;
                        margin-bottom: 8px;
                        border-radius: 10px;

                        &.active {
                            border-radius: 4px;
                            border: 2px solid rgba(24, 1, 72, 1);
                            opacity: 1;
                            background-color: rgba(255, 255, 255, 1);
                        }

                        &>button {
                            width: 60%;
                            display: block;
                        }

                        button {
                            background-color: transparent;
                            border: 0;
                            outline: none;
                            opacity: 1;
                            color: #505050;
                            font-family: "public-sans";
                            font-size: 18px;
                            font-weight: 400;
                            text-align: left;
                            line-height: 32px;

                            &:focus {
                                box-shadow: none;
                            }
                        }

                        .edit-tag-container {
                            width: 50%;
                            position: absolute;
                            right: 0;
                            top: 47px;
                            border-radius: 10px 3px 10px 3px;
                            box-shadow: 0px 0px 6px 3px rgba(4, 34, 107, 0.113227);
                            opacity: 1;
                            background-color: rgba(255, 255, 255, 1);
                            z-index: 12;

                            button {
                                width: 100%;
                                padding: 10px;
                                border-bottom: 1px solid #eee;
                                margin-bottom: 0;
                                text-align-last: left;

                                &:disabled {
                                    opacity: 0.4;
                                }
                            }
                        }
                    }
                }
            }

            h3 {
                opacity: 1;
                color: rgba(55, 55, 55, 1);
                font-family: "public-sans";
                font-size: 24px;
                font-weight: 700;
                text-align: left;
                line-height: 32px;
                margin-bottom: 19px;
            }

            .manage-card {
                background-color: #fff;
                padding: 17px 24px 24px;

                h4 {
                    opacity: 1;
                    color: rgba(80, 80, 80, 1);
                    font-family: "public-sans";
                    font-size: 22px;
                    font-weight: 400;
                    text-align: left;
                    line-height: 32px;
                }

                span {
                    opacity: 1;
                    color: rgba(80, 80, 80, 1);
                    font-family: "public-sans";
                    font-size: 18px;
                    font-weight: 700;
                    text-align: left;
                    line-height: 32px;


                }

                &>p {
                    opacity: 1;
                    color: rgba(143, 143, 143, 1);
                    font-family: "public-sans";
                    font-size: 18px;
                    font-weight: 400;
                    font-style: normal;
                    line-height: 26px;
                    display: -webkit-box;
                    -webkit-line-clamp: 5;
                    -webkit-box-orient: vertical;
                    text-overflow: ellipsis;
                    overflow: hidden;
                    white-space: normal;
                    max-height: 170px;
                }
            }
        }
    }

    .group-list-wpr {
        // width: 100%;
        overflow: auto;
        margin: 32px 0 24px;
        padding-left: 15px;
        white-space: nowrap;

        @media (max-width: 992px) {
            margin: 24px 0;
            padding-left: 0;
        }

        .group-list {
            margin-right: 1.5rem;
            border-radius: 32.4px;
            opacity: 1;
            background-color: rgba(248, 251, 255, 1);
            text-align: center;
            border: 1px solid transparent;
            padding: 11px 60px;
            white-space: wrap;

            @media (max-width: 992px) {
                padding: 11px 30px;
            }

            &.active {
                border: 2px solid rgba(24, 1, 72, 1);
                background-color: rgba(255, 255, 255, 1);
                box-shadow: none;
            }


            span {
                opacity: 1;
                color: rgba(102, 102, 102, 1);
                font-family: "public-sans";
                font-size: 18.9px;
                font-weight: 500;
                font-style: normal;
                letter-spacing: 0px;
                text-align: center;
                line-height: 28.8px;


            }
        }
    }

    .btn-transparent {
        padding: 0;
    }

    .users-list {

        span {
            opacity: 1;
            color: rgba(55, 55, 55, 1);
            font-family: "public-sans";
            font-size: 24px;
            font-weight: 700;
            text-align: left;
            line-height: 32px;

            &.license {
                font-weight: 400;
            }
        }

        .people-content {
            margin-top: 19px;

            @media (max-width: 992px) {
                margin-top: 14px;
            }
        }

        ul {
            padding: 0;

            li {
                background-color: #fff;
                padding: 20px 24px;
                margin-bottom: 8px;
                position: relative;

                @media (max-width: 992px) {
                    margin-bottom: 16px;
                }

                span {
                    opacity: 1;
                    color: rgba(80, 80, 80, 1);
                    font-family: "public-sans";
                    font-size: 18px;
                    font-weight: 400;
                    text-align: left;
                    line-height: 32px;

                    &.inactive {
                        color: #006BF1;
                    }

                    &:nth-child(4) {
                        width: 110px;
                    }

                }


                .action-container {

                    button {
                        background-color: transparent;
                        border: 0;
                        outline: none;
                    }

                    .show {
                        display: block;
                    }

                    .hide {
                        display: none;
                    }

                    .actions {
                        border-radius: 10px 3px 10px 3px;
                        box-shadow: 0px 0px 6px 3px rgba(4, 34, 107, 0.113227);
                        opacity: 1;
                        position: absolute;
                        top: 62%;
                        right: 3%;
                        width: 23%;
                        background-color: #fff;
                        z-index: 1;

                        @media (max-width: 600px) {
                            width: 45%;
                        }



                        p {
                            padding: 10px;
                            border-bottom: 1px solid #eee;
                            margin-bottom: 0;
                            color: rgba(80, 80, 80, 1);
                            font-family: "public-sans";
                            font-size: 18px;
                            font-weight: 400;
                            text-align: left;
                            line-height: 32px;



                            a {
                                color: rgba(80, 80, 80, 1);
                            }
                        }
                    }
                }
            }
        }
    }

    .modal-container {
        position: fixed;
        width: 100%;
        height: 100vh;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: rgba(238, 247, 255, .7);
        z-index: 1111;

        .content {
            max-width: 397px;
            padding: 24px;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            border-radius: 10px 3px 10px 3px;
            box-shadow: 0px 0px 6px 3px rgba(4, 34, 107, 0.113227);
            opacity: 1;
            background-color: rgba(255, 255, 255, 1);

            h3 {
                opacity: 1;
                color: rgba(55, 55, 55, 1);
                font-family: "public-sans";
                font-size: 19px;
                font-weight: 700;
                font-style: normal;
                letter-spacing: 0px;
                text-align: center;
                line-height: 32px;
            }

            p {
                opacity: 1;
                color: rgba(143, 143, 143, 1);
                font-family: "public-sans";
                font-size: 18px;
                font-weight: 400;
                text-align: center;
                line-height: 26px;
            }

            button {
                border-radius: 32.400002px;
                opacity: 1;
                color: #fff;
                border: 0;
                outline: 0;
                width: 164px;
                height: 40px;
                margin: 0 auto;

                &:not(.cancel) {
                    background-color: rgba(0, 107, 241, 1);
                }
            }
        }
    }

    .generate-report {
        position: relative;

        @media (max-width: 767px) {
            display: flex;
            justify-content: space-between !important;
        }

        .content-data {
            @media (max-width: 767px) {
                flex-basis: 46%;
            }

            a {

                @media (max-width: 767px) {
                    display: inline-block;
                    width: 100%;
                }
            }

        }

        .btn-text {

            @media (max-width: 1100px) {
                min-width: 150px
            }

            @media (max-width: 767px) {
                flex-basis: 46%;
                min-width: unset;
            }
        }

        button {
            &.generate {
                background-color: #006BF1;
            }
        }

        .report-container {
            width: 397px;
            position: absolute;
            top: 120%;
            right: 54%;
            border-radius: 10px 3px 10px 3px;
            box-shadow: 0px 0px 6px 3px rgba(4, 34, 107, 0.113227);
            opacity: 1;
            background-color: rgba(255, 255, 255, 1);
            z-index: 5;
            padding: 24px;

            h3 {
                opacity: 1;
                color: rgba(55, 55, 55, 1);
                font-family: "public-sans";
                font-size: 19px;
                font-weight: 700;
                font-style: normal;
                text-align: left;
                line-height: 32px;
            }

            p {
                opacity: 1;
                color: rgba(143, 143, 143, 1);
                font-family: "public-sans";
                font-size: 18px;
                font-weight: 400;
                text-align: left;
                line-height: 26px;
            }

            span {
                opacity: 1;
                color: rgba(24, 1, 72, 1);
                font-family: "public-sans";
                font-size: 18.9px;
                font-weight: 700;
                text-align: center;
                line-height: 28.8px;
                padding-right: 8px;

                &:first-of-type {
                    border-right: 1px solid #979797;
                    margin-right: 8px;
                }

            }

            .report-button-container {
                margin-top: 25px;
            }

            button {
                width: 48%;

            }
        }
    }
}

.admin-form-container {


    .cards-box {
        label {
            opacity: 1;
            color: rgba(55, 55, 55, 1);
            font-family: "public-sans";
            font-size: 20px;
            font-weight: 700;
            text-align: left;
            line-height: 32px;
            margin-bottom: 10px;
        }

        p {
            opacity: 1;
            color: rgba(55, 55, 55, 1);
            font-family: "public-sans";
            font-size: 16px;
            font-weight: 500;
            font-style: normal;
            text-align: left;
            margin-top: 16px;
        }
    }
}

.user-company-detail {

    .form-group:first-of-type {
        margin-top: 48px;
    }


    .title {
        opacity: 1;
        color: rgba(55, 55, 55, 1);
        font-family: "public-sans";
        font-size: 22px;
        font-weight: 700;
        text-align: left;
        line-height: 32px;
        margin-bottom: 15px;
        display: inline-block;
    }



    .user-details {
        border-radius: 4px;
        opacity: 1;
        background-color: #fff;
        padding: 32px 0;
        justify-content: center !important;
        text-align: center;
        align-items: center;

        @media (max-width: 992px) {
            flex-direction: column;
        }

        .image {
            padding-right: 5%;
            border-right: 1px solid #eee;
            margin-bottom: 35px;

            @media (max-width: 992px) {
                border-bottom: 1px solid #eee;
                border-right: 0;
                padding-bottom: 25px;
                width: 80%;
                padding-right: 0;
            }

            img {
                max-width: 65px;
            }

        }



        .user-company-name {
            padding-left: 5%;
            flex-basis: 46%;

            @media (max-width: 992px) {
                padding-left: 0;
            }

            h4 {
                word-break: break-all;
            }
        }

    }

}

header {
    .admin-header-container {
        position: relative;

        &:hover .admin-header-dropdown {
            display: block;
        }

        .chevron-rotate-diemension-container {
            display: flex;
            align-items: center;
        }

    }

    .admin-header-dropdown {
        position: absolute;
        width: 50%;
        min-width: 120px;
        top: 100%;
        background: #fff;
        border-radius: 10px 3px 10px 3px;
        box-shadow: 0px 0px 6px 3px rgba(4, 34, 107, 0.113227);
        opacity: 1;
        background-color: rgba(255, 255, 255, 1);
        display: none;
        z-index: 100;

        p {
            margin-bottom: 0;
            padding: 10px;

            &:not(:last-of-type) {
                border-bottom: 1px solid #eee;
            }

            a {
                color: #505050;
                font-family: "public-sans";
            }

        }

        button {
            border: 0;
            outline: none;
            background-color: #fff;
            font-family: "public-sans";
        }
    }
}


.user-tag-detail {
    .tag-container {
        position: relative;

        .tag-field {

            ul {
                padding: 7px;
                list-style-type: none;

                li {
                    white-space: nowrap;
                    padding: 7px 10px;
                    border: 1px solid #000;
                    background-color: transparent;
                    border-radius: 100px;

                    &:not(:first-child) {
                        margin-left: 5px;
                    }
                }
            }

            .input-group {
                margin-bottom: 0 !important;

                .tag-creation-form {
                    @media (max-width: 767px) {
                        margin-left: 0 !important;
                    }
                }

                button {
                    background-color: #180148;
                    border: none;
                    outline: none;
                    box-shadow: none;
                    color: #fff;
                    min-width: 150px;
                    // height: auto !important;
                    font-family: "public-sans";

                    @media (max-width: 767px) {
                        margin-top: 15px;
                        height: 42px;
                        flex: 1 1 30%;
                        margin-left: 0;
                    }
                }
            }

        }

        input:focus {
            &+ul {
                display: block;
            }
        }

        div.tag-list {
            width: 80%;
            list-style-type: none;
            padding-left: 0;
            position: absolute;
            top: 110%;
            right: 0;
            border-radius: 10px 3px 10px 3px;
            box-shadow: 0px 0px 6px 3px rgba(4, 34, 107, 0.113227);
            opacity: 1;
            background-color: rgba(255, 255, 255, 1);
            z-index: 5;
            padding-bottom: 0;

            @media (max-width: 992px) {
                position: static;
                width: 100%;
                border-radius: unset;
                margin-top: 5px;
            }

            ul {
                list-style-type: none;
                padding-left: 0;
                max-height: 170px;
                overflow-y: scroll;
                margin-bottom: 0;
            }

            li {
                padding: 10px;
                border-bottom: 1px solid #eee;
            }

            button {
                width: 100%;
                background-color: #fff;
                border: 0;
                outline: none;
                text-align: left;
            }

            &>button {
                padding: 0 15px;
            }
        }

        .tag-field {
            flex-wrap: wrap;


            // &:hover + .tag-list  {
            //   display: block;
            // }

            .tag-data {
                flex-wrap: wrap;
                background-color: #fff;
                width: 100%;

                li {
                    margin-top: 5px;

                    span {

                        &:first-of-type {
                            padding-left: 3px;
                        }

                        img {
                            display: inline-block;
                            width: 9px;
                            margin: -3px 3px 0 0;
                        }
                    }
                }
            }
        }

        button.cancel {
            display: none;
        }

        label {
            span {
                opacity: 1;
                color: rgba(55, 55, 55, 1);
                font-family: "public-sans";
                font-size: 20px;
                font-weight: 700;
                text-align: left;
                line-height: 32px;
            }
        }

    }

    button.cancel {
        background-color: transparent;
        border: 1px solid #180148;
        color: #180148 !important;
    }

}

.chevron-rotate {
    transform: rotate(270deg),

}

.chevron-rotate-home {
    transform: rotate(270deg),

}

.absolute {
    position: 'absolute';
    top: 209px; // computed based on child and parent's height
    left: 400px;
}

.chevron-rotate-diemension {
    width: 16px;
    height: 8px;

}

.just {
    justify-content: center;
}


.edit-user {
    .company-select {
        appearance: none;
        -moz-appearance: none;
        -webkit-appearance: none;
        background-color: #d9dfed;
        color: #000;
    }
}

.form-select {
    position: relative;

    &::after {
        content: "";
        width: 10px;
        height: 6px;
        display: inline-block;
        position: absolute;
        top: 50%;
        right: 15px;
        transform: translateY(-50%);
        pointer-events: none;
        background: url("../../Images/chevron_down.svg") no-repeat center/cover;
    }

    select {
        appearance: none;
        -webkit-appearance: none;
        -moz-appearance: none;
    }
}


.login-screen {
    .content-info {
        figure {
            margin: 0 0 3rem 0rem;
        }

    }
}

.login-button {
    width: 200px;
}

.page-conform {
    padding-top: 30px;
}

.logo-width {
    width: 40px;
}

// styling for admin forms 
.form-mobile-view {

    .form-check {
        label {
            opacity: 1;
            font-family: "public-sans";
            font-size: 16px;
            font-weight: 400;
            text-align: left;
            line-height: 29px;
        }
    }

    @media (max-width: 992px) {
        display: flex;
        flex-direction: column;

        .title {
            opacity: 1;
            font-family: "public-sans";
            font-size: 18px;
            line-height: 32px;
        }

        .col-md-6 {
            max-width: 100% !important;
        }

        .admin-form-container {
            max-width: unset;
            padding-bottom: 50px;


            div.row {
                @media (max-width: 992px) {
                    // justify-content: flex-start !important;
                    // width: 100%;
                    display: block;
                }

                .col-md-10 {
                    @media(max-width: 992px) {
                        max-width: 100% !important;
                    }
                }
            }


        }

        .wrpfrom-mobile {
            padding: 0 24px;
            margin-top: 24px;
        }

        .heading-with-logo {
            margin-bottom: 24px;

            h3 {
                opacity: 1;
                color: rgba(55, 55, 55, 1);
                font-family: "public-sans";
                font-size: 22px;
                font-weight: 700;
                text-align: left;
                line-height: 32px;
                margin-bottom: 16px !important;
            }

            p {
                opacity: 1;
                color: rgba(143, 143, 143, 1);
                font-family: "public-sans";
                font-size: 16px;
                font-weight: 400;
                text-align: left;
                line-height: 27px;
            }

        }

        .cards-box {


            label {
                opacity: 1;
                color: rgba(55, 55, 55, 1);
                font-family: "public-sans";
                font-size: 18px;
                font-weight: 700;
                text-align: left;
                line-height: 32px;
                margin-bottom: 8px;
            }

            .form-group {
                margin-bottom: 24px;
            }

            select {
                height: 62px;
                font-size: 18px;
            }

            input {
                font-size: 18px;
                height: 62px;
                border-radius: 4px;
                background-color: rgba(255, 255, 255, 1);
            }

            .checkmark {
                background-color: #fff !important;
            }

            .checkbox-container input:checked~.checkmark {
                background-color: #1c004b !important;
            }

            .form-check {
                label {
                    opacity: 1;
                    font-family: "public-sans";
                    font-size: 16px;
                    font-weight: 400;
                    text-align: left;
                    line-height: 29px;
                }
            }

            .submit {
                margin-left: 0;

            }


        }

        .btn-text {
            min-width: 267px;
            height: 62px;
        }

        .txt-left {
            margin-top: 36px;
        }

        .text-color-violet-forgot {
            opacity: 1;
            color: rgba(0, 107, 241, 1);
            font-family: "public-sans";
            font-size: 18px;
            font-weight: 700;
            text-align: left;
            line-height: 32px;
        }

        .wrpfrom-mobile {

            @media (max-width: 992px) {
                background-color: transparent;
                box-shadow: none;
            }
        }

        button {
            margin: 0;

            @media (max-width: 767px) {
                // margin: 0 25px;
            }
        }

        .admin-form-container {
            margin-top: 45px;

            @media (max-width: 767px) {
                margin-top: 0;
            }
        }
    }

    .button-container {
        @media (max-width: 992px) {
            display: flex;
            justify-content: flex-start;
            width: 100%;
        }

        @media (max-width: 420px) {
            flex-wrap: wrap;
        }

        button {

            @media (max-width: 767px) {
                min-width: unset;
                flex-basis: 48%;
            }

            &:not(:first-child) {
                @media (max-width: 420px) {
                    margin-top: 20px;
                }
            }
        }
    }
}


.people-content {
    .first_name {
        width: 15%;
    }

    .email {
        width: 45%;
    }

    .job_title {
        width: 30%;
    }

    .menu-button {
        padding: 0 0 0 1rem;
    }


}

.people-manager {

    button {

        @media (max-width: 992px) {
            box-shadow: none !important;
        }
    }

    .toggleReport {
        display: none;
        font-size: 10px;
        line-height: 0;

        span.line {
            display: inline-block;
            width: 18px;
            height: 2px;
            border-radius: 2px;
            background: #fff;
            margin-bottom: 2
        }
    }

    .row {
        @media (max-width: 992px) {
            flex-direction: column;
        }

        .col-md-3,
        .col-md-9 {

            @media (max-width: 992px) {
                max-width: 100%;

            }
        }

    }

    .people-card-wrapper {

        @media (max-width: 992px) {
            &>div {
                flex-direction: column;
            }

            &>div:last-of-type {
                flex-direction: row;
            }
        }
    }

}

.mobileGroupSelection {
    display: none;
}

@media (max-width: 767px) {
    .mobileGroupSelection {
        display: block;
        position: absolute;
        top: 0;
        right: 0;
        padding: 10px;
        z-index: 999;
        font-weight: bold;

        >span {
            font-weight: bold;
            display: inline-block;
            max-width: 230px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }


}

@media (max-width: 992px) {
    .users-list {
        padding-top: 1.8rem;
    }


    .group-name {
        color: #fff
    }

    .mobilegroupselect {
        background: transparent;
        color: #fff;
        border: none;
        font-size: 12px;
        padding: 0 10px;


    }

    .people-manager .generate-report .report-container {
        right: inherit;
        top: 80%;
        width: 367px
    }

    .people-manager {
        .container {
            width: 94%;

            .people-manage-notice .manage-card {}
        }

        .toggleReport {
            width: 45px;
            height: 45px;
            border-radius: 16px;
            border: none;
            display: block;
            background-color: transparent;

            img {
                width: 100%;
            }
        }

        .title {
            padding-bottom: 10px;

            @media (max-width: 992px) {
                padding-bottom: 18px;
            }

        }
    }

    .people-content {

        .first_name,
        .email,
        .job_title {
            display: inline-block;
            width: 100%;
            padding: 0.5rem 0;
            color: #505050
        }

        .active {
            color: #505050
        }

        .job_title {
            font-weight: bold;
        }

    }

    .tags-form .form-control {
        border: 1px solid #ced4da !important
    }

    .people-manage-notice {
        background: #fff;
        padding: 10px;

        h3 {
            margin-bottom: 0 !important;
        }
    }
}


.mobilegroupselect {
    @media (max-width: 767px) {
        max-width: 200px;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
    }
}



.hideHeader {

    .landingpgs,
    .pagepg404,
    .lumenai-navbar,
    .nonreport-login {
        display: none !important;
    }
}


.user-detail-form-page {
    padding-bottom: 100px;
}

.previous-activity-text {
    color: #BF06FF !important;
}

.summary-new {
    .container {

        .associate-list {
            font-family: $font-ubuntu !important;

            @media (max-width: 992px) {
                width: 100%;
                padding: 0;
            }

            @media (max-width: 767px) {
                justify-content: space-between !important;
            }

            li {
                @media (max-width: 767px) {
                    flex-basis: 48% !important;
                    margin-right: 2% !important;
                    word-break: break-word;

                    &:nth-child(2n) {
                        margin-right: 0 !important;
                    }

                }

                @media (max-width: 399px) {
                    flex-basis: 100% !important;

                    &:nth-child(2n) {
                        margin-right: 0 !important;
                    }

                }

            }

        }

        .associate-trait-btn {
            background-color: #fff;
            color: #04226B !important;
            font-size: 13px;
            font-weight: 700;
            font-family: $font-ubuntu !important;
        }

        .key-points {

            .key-points-container {
                border-top: 2px solid #fff;

                &:first-of-type {
                    margin-top: 24px;
                }

                &:last-of-type {
                    padding-top: 24px;
                }
            }

            h3 {
                font-size: 24px;
                font-weight: 700;
                font-family: "public-sans";
                margin: 24px 0 21px;
            }

            .sub-title {
                font-size: 16px;
                font-family: "public-sans";
                font-weight: 700;
            }



            .key-point-list {

                li>span {
                    font-size: 16px;
                    font-family: "public-sans";
                    font-weight: 700;
                }

                p {
                    font-size: 14px;
                    font-weight: 400;
                    font-family: "public-sans";

                    span {
                        font-weight: 700;
                    }
                }
            }

            .skills-summary-report {
                .sub-title {
                    font-size: 16px;
                }

                li span {
                    font-size: 14px;
                }

                .summary-score-title {
                    width: 100%;
                    font-size: 16px;
                    display: block;
                    max-width: 100% !important;
                    margin-bottom: 16px;
                }
            }
        }
    }

    .associated-traits {

        @media (max-width: 992px) {
            .associate-list {
                width: 73%;
            }

            .associate-list+div {
                width: 25%;
            }


        }

        .associate-list {
            flex-basis: 79% !important;
            max-width: unset !important;

            @media (max-width: 992px) {
                flex-basis: 73% !important;
            }

            @media (max-width: 767px) {
                flex-basis: 100% !important;
            }

        }

        .associate-list+div {
            flex-basis: 20% !important;
            max-width: unset !important;

            @media (max-width: 992px) {
                flex-basis: 25% !important;
            }

            @media (max-width: 767px) {
                flex-basis: 100% !important;
                flex-direction: row !important;
                justify-content: space-between !important;

                button,
                a {
                    flex-basis: 48%;
                    margin-right: 0 !important;
                }
            }

            @media (max-width: 400px) {
                flex-basis: 100% !important;
                flex-direction: column !important;
                justify-content: center !important;
            }

            button,
            a {
                white-space: nowrap;
            }

            span {
                white-space: nowrap;
            }
        }

        @media (max-width: 767px) {

            .associate-list,
            .associate-list+div {
                width: 100%;
            }

            button {
                width: 100% !important;
            }
        }
    }

}

.modal-summary-new {
    .modal-header {
        background-color: #fff;
        padding: 45px 1rem 65px;
        background: url("../../Images/Desktop_curve.svg") no-repeat center 60px/cover, #fff;
        border-bottom: 0;

        button {
            opacity: 1;
        }
    }

    .modal-content {
        background-color: #180148;
    }

    .modal-body {
        border-top: 0 !important;
    }
}

.template {

    h2 {
        font-size: 2rem;
        font-weight: 700;
        font-family: "public-sans";
        color: $color-White;
        width: 78%;
        margin: 0 auto;
        margin-bottom: 1.5rem;

        @media (max-width: 767px) {
            width: 100%;
        }
    }

    p {
        font-size: 1.25rem;
        font-family: "public-sans";
        color: $color-White;
        margin-bottom: 3.125rem;

    }

    .button {
        background-color: $color-White;
        font-size: 1.313rem;
        line-height: 2rem;
        border-radius: 2.25rem;
        padding: 0.8rem 3rem 0.8rem;
        display: inline-block;
        color: #000;
        background-color: #fff;
        font-family: "public-sans";
        font-weight: 700;

    }

    .poweredBy {
        margin-top: 2.25rem;


        span {
            color: rgba(255, 255, 255, 0.5);
            font-family: "public-sans";
            font-size: 1.25rem;
            font-weight: 700;
            font-style: Bold;
            text-align: center;

            &:first-of-type {
                margin-right: 1rem;
            }
        }



        img {
            width: 21%;
            opacity: 0.5;
            display: inline-block;
            margin-right: 0.3rem;
            vertical-align: baseline;
        }
    }
}

.template-footer {
    width: 100%;
    background-color: #EFF7FF;
    text-align: center;
    padding: 2.375rem 0;
    // position: absolute;
    // bottom: 0;

    h4 {
        font-family: "public-sans";
        font-weight: 700;
        font-size: 1.5rem;
        color: #04226C;
        margin-bottom: 0.375rem;
    }

    p {
        width: 50%;
        margin: 0 auto;
        font-size: 1.25rem;
        font-family: "public-sans";
        color: rgba(17, 17, 18, 0.66);
        margin-bottom: 1.438rem !important;

        @media (max-width: 767px) {
            width: 100%;
        }
    }

    .visit-site {
        color: rgb(4, 34, 108);
        padding-bottom: 0.1rem;
        border-bottom: 0.16rem solid rgb(4, 34, 108);
        font-weight: 700;
        font-family: "public-sans";
        display: inline-block;
        font-weight: 700;
    }
}

.top-bar {
    width: 100%;
    background-color: #221044;

    img {
        width: 6.25rem;
        display: inline-block;
        margin-left: 3rem;

        @media (max-width: 767px) {
            margin-left: 1.5rem;
        }

    }
}

.tool-tip {
    background-color: red;
}


.donut-box {
    // background-color: blue;
    width: 216px;
    height: 216px;
    margin-left: auto;
    margin-right: auto;
    position: relative;

    .slice-size {
        width: 108px;
        height: 108px;
        background-repeat: no-repeat;
        background-size: 100% 100%;
        background-repeat: no-repeat;
        position: absolute;
    }

    .one-slice {
        top: 0px;
        right: 0px;
        background-image: url("../../Images/inactive_graph/one__inactive.png");
    }

    .two-slice {
        bottom: 0px;
        right: 0px;
        background-image: url("../../Images/inactive_graph/two_inactive.png");
    }

    .three-slice {
        bottom: 0px;
        left: 0px;
        background-image: url("../../Images/inactive_graph/three_inactive.png");
    }

    .four-slice {
        top: 0px;
        left: 0px;
        background-image: url("../../Images/inactive_graph/four_inactive.png");
    }

    .activeSlice.one-slice {
        background-image: url("../../Images/active_graph/one__active.png");
    }

    .activeSlice.two-slice {
        background-image: url("../../Images/active_graph/two_active.png");
    }

    .activeSlice.three-slice {
        background-image: url("../../Images/active_graph/three_active.png");
    }

    .activeSlice.four-slice {
        background-image: url("../../Images/active_graph/four_active.png");
    }
}


.progress-card {
    // height: 73px;
    // width: 350px;
    // top: 25%;
    // left:5%;
    position: relative;

    .progress-content {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .inside-box {
            display: flex;
            align-items: center;
            text-align: left;
        }
    }

    // border-radius: 10px 3px 10px 3px;
    // background-color: transparent;
    // box-shadow: 0 0 6px 3px rgba(4, 34, 107, 0.11);
    // @media (min-width: 576px) {
    //   top: 0;
    //   float: left;
    //   left: 20%;
    // }
    .card-icon {
        width: 25px;
    }

    .content {

        // float: right;
        @media (min-width: 576px) {
            // margin-left: 50px;
        }

        .title {
            // bottom: 10px;
            color: $color-Alice-Blue;
            font-family: "public-sans";
            font-size: $font-16;
            font-weight: $font-bold;
            letter-spacing: 0;
            line-height: $line-height18;
            margin-left: 17px;
            display: inline-block;
            // @media (min-width: 576px) {
            //   margin-left: 12px;
            //   margin-right: 10px;
            // }
        }

        .percents {
            letter-spacing: 0.85px;
            color: $color-White;
        }
    }

    .progressbar {
        // width: 300px;
        height: 5px;
        margin-top: 10px;
        background-color: #381474;
    }
}

.bg-aliceBlue {
    background-color: $color-Alice-Blue !important;
}

.bg-white {
    background-color: $color-White !important;
}

.bg-violet {
    background-color: $color-Violent-Violet !important;
}

.bg-dithered {
    background-color: $color-dithered !important;
}

.bg-Electric-Purple {
    background-color: $color-Electric-Purple !important;
}

.bg-color-pink {
    background-color: $color-pink !important;
}

.bg-lightBlue {
    background-color: $color-lightBlue !important;
}

.bg-Cyan {
    background-color: $color-cyan !important;
}

.update-logo {
    width: 37%;
    display: block;
    margin: 0 auto;
}

#send-email {
    background-color: #fff;
    color: #000;
    padding: 1rem 3rem;
    font-weight: 800;
    font-family: "public-sans";
    border-radius: 2rem;
    margin-top: 1rem;
    display: inline-block;
}

.paywall {
    
    min-height: 100vh;
    flex-grow: 1;
    background: url('../../Images/PaywallBG.jpg') no-repeat 80%/cover;
    background-position: top;
    color: #fff;
    padding-top: 5rem;

    @media (max-width: 992px) {
        padding-top: 0rem;
    }

    @media (max-width: 992px) and (orientation: landscape) {
        background: url('../../Images/PaywallBG_mobile.jpg') no-repeat 100%/cover;
    } 

    @media (max-width: 767px) {
        padding-bottom: 20rem;
        padding-top: 2rem;
        background: url('../../Images/PaywallBG_mobile.jpg') no-repeat 100%/cover;
    }

    .pie-graph-chart {
        width: 25rem;
        margin: 0 auto;

        @media (max-width: 800px) {
            width: 20rem;
        }
    }

    .lock-icon-container {
        background-color: #fff;
        border-radius: 2rem;
        display: inline-block;
        margin-top: 1rem;
        width: 3rem;

        img {
            width: 100%;
        }
    }


    .lock-detail-container {
        margin: 0 auto;

        @media (max-width: 767px) {
            max-width: 90%;
        }
        
        .lock-data {

            margin-top: 2rem;

            p {
                margin-top: 1rem;
                font-size: 1.125rem;
                font-weight: 700;
                font-family: "public-sans";
            }
        }

        .lock-description {

            margin-left: 4rem;

            @media (max-width: 992px) {
                margin-left: 0;
            } 

            & > p {
                margin-top: 2.125rem;
                font-size: 1rem;
                line-height: 1.313rem;
                font-family: "public-sans";
                font-weight: 700;
            }
            
            div {
                text-align: center;
                background-color: #fff;
                color: black;
                border-radius: 1.5rem 0.375rem 1.5rem 0.375rem;
                padding: 2rem 1.5rem 1.5rem;
                
                h2 {
                    opacity: 1;
                    color: #221044;
                    font-family: "public-sans";
                    font-size: 1.5rem;
                    font-weight: 700;
                    text-align: center;

                }

                p {
                    margin-top: 1rem;
                    font-size: 1.25rem;
                    font-weight: 700;
                    font-family: "public-sans";
                    line-height: 1.688rem;
                    color: rgba(17, 17, 18, 0.66);
                    font-weight: 400;

                    &:last-of-type {
                        color: #221044;
                        font-size: 1.125rem;
                        font-weight: 700;
                    }
                }

                button {
                    min-width: 16.688rem;
                    flex-basis: 48%;
                    text-align: center;
                    padding: 0.75rem 0;
                    font-size: 1.313rem;
                    line-height: 2rem;
                    font-family: "public-sans";
                    color: $color-White;
                    font-weight: 700;
                    background-color: #845BA6;
                    border-radius: 2.25rem;
                    border: 0;
                    outline: 0;
                    margin-top: 3.25rem;
                    display: inline-block;
                    margin-right: 1rem;
                }

            }
        }
    }

    
}

.modal {
    & > .modal-dialog {

        &:first-of-type > .modal-content {
            background-color: #221044 !important;
    
        }
    }
}



.stripe-submit {
    min-width: 16.688rem;
    flex-basis: 48%;
    text-align: center;
    padding: 0.75rem 0;
    font-size: 1.313rem;
    line-height: 2rem;
    font-family: "public-sans";
    color: $color-White;
    font-weight: 700;
    background-color: #845BA6;
    border-radius: 2.25rem;
    border: 0;
    outline: 0;
    margin-top: 3.25rem;
    display: inline-block;
    margin-right: 1rem;
}

.loader {
    position: relative;
    width: 100%;
    height: 100vh;
    display: inline-block;

    h1 {
        color: rgba(0,0,0,0.3);
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
}

.feedback-error {
    font-size: 0.9rem !important;

    @media (max-width: 767px) {
        bottom: -2.5rem !important;
    }
}