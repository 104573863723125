.start {
    width: 100%;
    height: 100vh;
    opacity: 1;
    // background-image: linear-gradient(170deg, rgb(14, 255, 255) 5%, rgb(0, 107, 241) 20%, rgb(0, 107, 241) 80%, rgb(191, 6, 255) 95%);
    // background-image: opacity: 1;
    background-color: #221044;
    position: relative;

    @media (max-width: 767px) {
        // height: auto;
        display: flex;
        justify-content: center;
        flex-grow: 1;
    }

    .start-title {
        color: #fff;
        font-size: 1.25rem;
        font-weight: 700;
        font-family: "public-sans";
        opacity: 0.5;
    }


    .template {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        text-align: center;
        max-width: 36rem;

        @media (max-width: 992px) {
            max-height: 100vh;
            padding: 2rem 0;
            overflow-y: auto;
            scrollbar-width: none;
        }

        @media (max-width: 767px) {
            max-width: 100%;
            width: 80%;
            padding: 5rem 0;
            position: static;
            transform: translate(0, 0);
        }

        h2 {
            width: 85%;

            @media (max-width: 992px) {
                width: 100%;
            }

            // @media (max-width: 992px) {
            //     width: 100%;
            // }
        }

        .button {
            background-color: #845BA6;
            color: #fff;
        }
    }

    .infinity {
        position: absolute;
        left: 0;
        width: 30%;
        bottom: 5%;
    }

    .content>.update-logo {
        width: 8.75rem;
        margin-bottom: 1.688rem;
    }
}