.assessment {
    background-color: #FCFAFE;

    .assessment-title {
        margin: 1.5rem 0;

        h2 {
            font-size: 1.5rem;
            font-family: "public-sans";
            color: #221044;
            font-weight: 700;
            margin-bottom: 0.375rem;
        }

        span {
            font-size: 1.25rem;
            font-family: "public-sans";
            color: rgba(17, 17, 18, 0.66);
        }
    }

    .answers-list {
        display: flex;
        justify-content: space-between;
        margin-top: 2.563rem;

        @media (max-width: 900px) {
            flex-direction: column;
        }
    }

    .assessment-content {
        max-width: 75%;
        margin: 0 auto;

        @media (max-width: 995px) {
            max-width: 90%;
        }

        @media (max-width: 767px) {
            max-width: 90%;
            padding-bottom: 10rem;
        }
    }

    .answer-container {
        flex-basis: 48%;
        padding: 1.5rem 1.5rem;
        border-radius: 1.5rem 0.375rem 1.5rem 0.375rem;
        border: 0.063rem solid #D3CBDA;
        background-color: #fff;

        @media (max-width: 767px) {
            flex-direction: column;
            padding: 1.5rem 1rem;
            margin-bottom: 2rem;
        }

        h3 {
            font-family: "public-sans";
            font-size: 1.25rem;
            color: #221044;
        }

        div.radio {
            display: flex;
            justify-content: space-between;
            align-items: flex-start;
            position: relative;
            padding: 1.438rem 0 1.438rem 2rem;

            &.active {
                &::before {
                    background-color: #221044;
                }
            }

            &:not(:first-of-type) {
                border-top: 0.125rem solid #D3CBDA;
            }

            &::before {
                content: "";
                width: 1rem;
                height: 1rem;
                border-radius: 100%;
                background-color: transparent;
                display: inline-block;
                border: 0.063rem solid #221044;
                position: absolute;
                left: 0.15rem;
                top: 1.76rem;

            }

            &::after {
                content: "";
                width: 1.3rem;
                height: 1.3rem;
                border-radius: 100%;
                background-color: transparent;
                display: inline-block;
                border: 0.063rem solid #221044;
                position: absolute;
                left: 0;
                top: 1.6rem;
            }

            p {
                flex: 1;
                font-size: 1.25rem;
                font-family: "public-sans";
                margin-right: 1rem;

                @media (max-width: 767px) {
                    font-size: 1rem;
                }
            }

            img {
                margin-top: 0.5rem;
            }
        }

        div.text {
            flex-basis: 48%;
            padding: 0.8rem;
            border-radius: 1rem;

            &.active {
                background-color: #221044;
                color: #fff !important;

                p {
                    color: #fff;
                }
            }

            p {
                flex: 1;
                font-size: 1.25rem;
                font-family: "public-sans";

                @media (max-width: 767px) {
                    font-size: 1rem;
                }
            }

            img {
                width: 0.875rem;
                height: 0.875rem;
            }
        }



    }

    .button-container {
        display: flex;
        justify-content: space-between;
        padding-bottom: 3rem;

        .prev,
        .next {
            min-width: 16.688rem;
            text-align: center;
            padding: 0.75rem 0;
            font-size: 1.313rem;
            line-height: 2rem;
            font-family: "public-sans";
            color: $color-White;
            font-weight: 700;
            border-radius: 2.25rem;
            border: 0;
            outline: 0;
            margin-top: 3.25rem;
            display: inline-block;
            cursor: pointer;

            @media (max-width: 767px) {
                min-width: 10.688rem;
                margin-top: 1.25rem;
            }

            &:disabled {
                opacity: 0.5;
                cursor: unset;
            }
        }

        .prev {
            background-color: #CED8E6;
        }

        .next {
            background-color: #845BA6;
            color: #fff;
        }

        @media (max-width: 767px) {
            position: fixed;
            bottom: 0;
            width: 90%;
            background-color: #FCFAFE;
        }

    }

    .qcard-size {
        flex-basis: 48%;
        padding: 1.5rem 2rem;
        border-radius: 1.5rem 0.375rem 1.5rem 0.375rem;
        border: 0.063rem solid #D3CBDA;
        background-color: #fff;

        @media (max-width: 992px) {
            margin-bottom: 2rem;
        }

        @media (max-width: 767px) {
            padding: 1.5rem 1rem;
        }

        h6 {
            font-size: 1.25rem;
            font-family: "public-sans";
            color: rgba(17, 17, 18, 0.66);
            line-height: 1.5rem;

            @media (max-width: 767px) {
                font-size: 0.9rem;
            }
        }


    }



}