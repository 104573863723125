.result-template {
    background: #221044;
    width: 100%;
    position: relative;
    flex-grow: 1;
    min-height: 80vh;

    .template {
        z-index: 1;
        position: relative;
        padding-bottom: 7rem;
        min-height: 75vh;

        @media (max-width: 1200px) {
            min-height: 84vh;
        }

        @media (max-width: 900px) {
            min-height: 82vh;
        }



        .content {
            display: flex;
            flex-direction: column;
            align-items: center;
            max-width: 34.75rem;
            text-align: center;
            margin: 0 auto;
            padding-top: 6rem;

            @media (max-width: 1366px) {
                width: 34.75rem;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -60%);
            }

            @media (max-width: 992px) {
                width: 34.75rem;
                max-width: 86%;
                position: static;
                transform: translate(0, 0);
            }

            @media(max-width: 767px) {
                max-width: 86%;
                position: static;
                transform: translate(0, 0);
            }

            h2 {
                width: 73%;
            }

            p {
                width: 73%;
            }

            .logo {
                width: 3rem;
                display: inline-block;
                margin-bottom: 2.063rem;
            }

            .button {
                padding: 1rem 4rem;

                &.view-result {
                    background-color: #845BA6;
                    color: #fff;
                }

                @media (max-width: 900px) {
                    line-height: 1rem;
                }

                &:first-of-type {
                    margin-right: 1rem;
                    flex-basis: 48%;
                    border: none;

                    @media (max-width: 767px) {
                        flex-basis: 100%;
                        margin-right: 0;
                        margin-bottom: 1rem;
                    }
                }
            }

            .start-again {
                color: $color-White;
                padding-bottom: 0.1rem;
                border-bottom: 0.08rem solid $color-White;
                font-weight: 700;
                font-family: "public-sans";
                display: inline-block;
                margin-top: 3.375rem;
            }

            &>div {
                width: 100%;

                form {
                    width: 100%;

                    .form-group {
                        display: flex;
                        flex-direction: column;
                        justify-content: flex-start;
                        text-align: left;

                        label {
                            color: $color-White;
                            font-family: "public-sans";
                            font-size: 1.25rem;
                            margin-bottom: 1rem;
                        }

                        input {
                            border-radius: 0.375rem;
                            background-color: $color-White;
                            padding: 0.5rem;
                            border: 0;
                            outline: 0;
                            box-shadow: none;
                            font-size: 1.25rem;
                        }


                    }

                    .button {
                        border: 0;
                        outline: 0;
                        box-shadow: none;
                        border: 0;
                        margin-top: 3.188rem;
                    }
                }
            }


        }



    }

    .infinity {
        width: 40%;
        position: absolute;
        right: 0;
        bottom: 10%;
        transform: rotate(180deg);
        opacity: 0.3;
    }

    .update-logo {
        width: 25%;
        margin-bottom: 1.6rem;
    }

    .email-button-container {
        justify-content: space-between !important;

        @media (max-width: 767px) {
            flex-wrap: wrap;
        }

        .button {

            @media (max-width: 767px) {
                flex-basis: 100%;
                margin-top: 0 !important;
            }
        }

        a {

            @media (max-width: 767px) {
                flex-basis: 100%;
            }
        }
    }
}