.code-form-data {
    font-family: 'public-sans';
    width: 50%;
    margin: 0 auto;
    min-height: 88vh;
    display: flex;
    flex-direction: column;

    @media (max-width: 992px) {
        width: 75%;
    }

    @media (max-width: 767px) {
        width: 88%;
    }

    h2 {
        color: #221044;
        font-family: "public-sans";
        font-size: 1.5rem;
        font-weight: 700;
        margin-top: 5rem;

        @media (max-width: 767px) {
            margin-top: 2rem;
        }
    }

    p {
        font-family: "public-sans";
        color: rgba(17, 17, 18, 0.66);
        font-size: 1.25rem;
        margin: 0.375rem 0 1.5rem;
    }

    h3 {
        display: block;
        margin-bottom: 1rem;
        font-size: 1.25rem;
        color: #221044;
        font-family: "public-sans";
    }

    .try-again {
        display: block;
        margin-bottom: 1rem;
        font-size: 1.25rem;
        color: #221044;
        font-family: "public-sans";
        margin-top: 3rem;
    }

    .user-code-form {
        flex-grow: 1;
        display: flex;
        flex-direction: column;

        form {
            flex-grow: 1;
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: flex-start;

            &  > div {
                flex-grow: 1;
                width: 80%;

                @media (max-width: 767px) {
                    width: 100%;
                }
            }

            .form-control:focus {
                color: #000;
                background-color: #fff;
                border-color: #221044;
                outline: 0;
                box-shadow: 0 0 0 0.25rem rgba($color: #221044, $alpha: 0.5);

            }
        }
    }

    .form-group {
        input {
            flex-basis: 15%;
            padding: 3rem 1.5rem;
            text-align: center;
            font-size: 1.5rem;

            @media (max-width: 767px) {
                flex-basis: 14%;
                padding: 1rem 1rem;
            }
        }
    }

    button:not(.Toastify__close-button) {
        min-width: 16.688rem;
        flex-basis: 48%;
        text-align: center;
        padding: 0.75rem 0;
        font-size: 1.313rem;
        line-height: 2rem;
        font-family: "public-sans";
        color: #fff;
        font-weight: 700;
        background-color: #845BA6 !important;
        border-radius: 2.25rem;
        border: 0;
        outline: 0;
        margin: 3.25rem 0 3rem;
        display: inline-block;
        // margin-right: 1rem;
    }
}