.question-card {
    background-color: #fff;
    padding: 1.375rem 2.063rem;
    border-radius: 1.5rem 0.375rem 1.5rem 0.375rem;
    border: 0.063rem solid #D3CBDA;

    h3 {
        font-size: 1.375rem;
        color: #221044;
        font-weight: 700;
        font-family: "public-sans";
        margin-bottom: 1rem;

    }

    p {
        font-size: 1.25rem;
        color: rgba(17, 17, 18, 0.66);
        font-weight: 400;
        font-family: "public-sans";
        margin-bottom: 1rem;
    }

    .hint {
        font-size: 1.25rem;
        font-weight: 400;
        color: rgba(154, 154, 154, 0.66);
        font-family: "public-sans";
        margin-left: 0.5rem;
    }

}