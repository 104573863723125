.data-capture {
    background-color: #FCFAFE;
    position: relative;
    padding: 2rem 0 5rem;

    img {
        position: absolute;
        opacity: 0.3;
        right: 0;
        top: 20%;
        transform: rotate(180deg);
    }

    .data-content {
        padding: 1.438rem 1.25rem;
        max-width: 50%;
        margin: 0 auto;


        @media (max-width: 900px) {
            max-width: 80%;
        }

        @media (max-width: 767px) {
            max-width: 96%;
        }

        h2 {
            color: #221044;
            font-family: "public-sans";
            font-size: 1.5rem;
            font-weight: 700;
        }

        p {
            font-family: "public-sans";
            color: rgba(17, 17, 18, 0.66);
            font-size: 1.25rem;
            margin: 0.375rem 0 1.5rem;
        }

        form {

            width: 100%;
            z-index: 1;
            position: relative;
            font-family: 'public-sans';

            &>div {
                margin-top: 2.5rem;

                input:not([type='checkbox']) {
                    width: 100%;
                    display: block;
                    border: none;
                    outline: none;
                    border: 0.1rem solid #D3CBDA;
                    border-radius: 0.375rem;
                    min-height: 2rem;
                    padding: 0.7rem 1rem;
                    background-color: $color-White;
                    font-family: 'public-sans';
                }
            }

            .css-13cymwt-control {
                background-color: $color-White;
                border: none;
                border: 0.1rem solid #D3CBDA;
                min-height: 2.89rem;
                font-family: "public-sans";
            }

            label {
                display: block;
                margin-bottom: 1rem;
                font-size: 1.25rem;
                color: #221044;
                font-family: 'public-sans';

            }

            button:not(.Toastify__close-button),
            a.skip {
                min-width: 16.688rem;
                flex-basis: 48%;
                text-align: center;
                padding: 0.75rem 0;
                font-size: 1.313rem;
                line-height: 2rem;
                font-family: "public-sans";
                color: #fff;
                font-weight: 700;
                background-color: #845BA6;
                border-radius: 2.25rem;
                border: 0;
                outline: 0;
                margin-top: 3.25rem;
                display: inline-block;
                // margin-right: 1rem;
            }

            a.skip {
                background-color: #221044;
                color: #fff;
                margin-right: 0;
            }

            .check-label {
                position: relative;
                
                .checkbox-field {
                    display: inline-block;
                    opacity: 0;
                    position: absolute;
                    top: 0;
                    height: 1px;
                    width: 1px;
    
    
                    &:has(> input:checked) + .checkbox-check::after {
                        content: "";
                        width: 20px;
                        height: 20px;
                        display: inline-block;
                        background: url('../../Images/check.svg') no-repeat center/cover;
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        transform: translate(-50%, -50%);
    
                    }
                }
    
                .checkbox-check {
                    width: 2rem;
                    box-shadow: none;
                    outline: none;
                    border: none;
                    outline: none;
                    border: 0.1rem solid #D3CBDA;
                    border-radius: 0.375rem;
                    height: 2rem;
                    padding: 0.7rem 1rem;
                    display: inline-block;
                    position: relative;
                    margin-right: 1rem;
                    background-color: #fff;
                }
                

                a {
                    font-weight: bold;
                }
            }



            &>div {
                position: relative;

                .feedback {
                    position: absolute;
                    top: 98%;
                }
            }

        }
    }
}