.view-results {
    opacity: 1;
    background-color: #221044;
    flex-grow: 1;
    display: flex;
    flex-direction: column;

    .progress-heading {
        font-family: "public-sans";
        font-size: 1.25rem;
        font-weight: 700;
        width: 94%;
        margin: 0 auto;
    }

    // .category-progress-summary {
    //     flex-basis: 80%;
    // }

    .level-data {
        opacity: 1;
        color: rgba(0, 255, 255, 1);
        font-family: "public-sans";
        font-size: 1.25rem;
        font-weight: 700;
        margin-bottom: 0;
        vertical-align: top;

        @media (max-width: 767px) {
            font-size: 0.9rem;
        }
    }

    .pie-graph-chart {
        max-width: 25rem;
        min-height: 300px;

        @media (max-width: 992px) {
            max-width: 350px;
        }
    }

    .associated-traits {
        p {
            color: #fff;
            font-family: "public-sans";
            font-size: 0.938rem;
            font-weight: 700;
        }

    }

    .associate-list {
        width: 100% !important;

        li {
            margin-right: 1rem !important;
            border-radius: 1.875rem;
            cursor: default;

            span {
                color: #fff;
                font-family: "public-sans";
                font-size: 0.938rem;
                font-weight: 700;
            }

            &:hover {
                border-color: #fff;
            }
        }
    }

    .chart-container {

        padding: 1.5rem 0 3rem;
        flex-grow: 1;

        @media (max-width: 900px) {
            flex-direction: column;
            align-items: center;
        }

        .pie-chart {
            margin-right: 5rem;

            @media (max-width: 992px) {
                margin-right: 2rem;
            }

            @media (max-width: 900px) {
                width: 80%;
                margin-right: 2rem;
            }

            @media (max-width: 767px) {
                width: 90% !important;
                flex-basis: 90%;
            }

            @media (max-width: 767px) {
                margin-right: 0;
            }
        }

        .progress-report-container {

            @media (max-width: 900px) {
                width: 80%;
                margin-top: 2rem;
            }

            @media (max-width: 767px) {
                flex-basis: 90%;
                width: 90% !important;
            }

            h3 {
                color: #fff;
                font-weight: 700;
                font-size: 1.5rem;
                font-family: "public-sans";
            }

        }

        .pie-graph-chart {
            margin: 0 auto;
        }

    }

    .progress-card {

        .card-icon {
            width: 1.438rem;
            margin-right: 1rem;
        }

        .progressbar {
            height: 0.313rem;
        }

        .titleWidthSize {
            font-size: 0.938rem;
            cursor: default;
        }

        .percents {
            font-size: 0.938rem;
            text-align: right !important;

            @media (max-width: 768px) {
                font-size: 0.8rem;
            }
        }
    }

    .lumenreport__sticky {
        bottom: 2.938rem;

        .key--icons {
            max-width: 1.188rem !important;
            margin-right: 0.63rem !important;
        }
    }

    .showlist__position {
        bottom: 6.25rem
    }

    .lumenreport__key--btn {
        width: 8rem !important;
        height: 2.688rem !important;

        @media (max-width: 767px) {
            width: auto !important;
        }
    }

    .showkey__box {
        width: 12.25rem;

        li {
            height: 2rem !important;

            .category--icon {
                max-width: 1rem !important;
                width: 1rem !important;
            }
        }
    }

    .read-lumen {
        background-color: #EEF7FF;
        padding: 2rem 0 4rem;
        color: #180148;
        font-family: "public-sans";

        @media (max-width: 767px) {
            padding: 2rem 0 2rem;
        }

        h3 {
            font-size: 1.25rem;
            line-height: 1.438rem;
            font-family: "public-sans";
            font-weight: 700;
        }

        .description {
            font-size: 1rem;
            line-height: 1.438rem;
            font-weight: 500;
            margin-bottom: 2.313rem;

            @media (max-width: 767px) {
                margin-bottom: 0rem;
            }
        }

        ul {

            padding-left: 0;
            flex-wrap: wrap;
            justify-content: space-between;

            li {
                flex-basis: 48% !important;

                @media (max-width: 767px) {
                    flex-basis: 100% !important;
                    margin-top: 2rem !important;
                }

                &:nth-child(n+3):nth-child(-n+4) {
                    margin-top: 3.375rem;
                }

                figure {
                    margin-right: 1.688rem;
                }

                p {
                    font-size: 0.875rem;
                    font-weight: 700;
                    font-family: "public-sans";
                    color: #180148;
                    margin-bottom: 0;
                }

                h4 {
                    font-size: 1.125rem;
                    font-family: "public-sans";
                    font-weight: 700;
                }

                &:first-of-type {
                    h4 {
                        color: #381474;
                    }
                }

                &:nth-child(2) {
                    h4 {
                        color: #0067FF;
                    }
                }
                &:nth-child(3) {
                    h4 {
                        color: #01F2F2;
                    }
                }
                &:last-of-type {
                    h4 {
                        color: #FF00FF;
                    }
                }
            }
        }
    }

    .breakdown-skills-container {

        background-color: #FCFAFE;
        padding: 3rem 0;

        .breakdown-title {
            color: #221044;
            font-size: 0.938rem;
            font-weight: 700;
            text-align: left;
            margin-bottom: 1.188rem;
            font-family: "public-sans";
        }

         ul {

            padding-left: 0;

            li {
                margin-bottom: 1rem
            }

            span {
                opacity: 1;
                font-size: 1rem;
                font-weight: 700;
                text-align: left;
                color: #221044 !important;
                margin-bottom: 0.5rem;
                display: inline-block;
                font-family: "public-sans";
            }

            p {
                color: #221044 !important;
                flex-basis: 78%;
                opacity: 1;
                font-size: 0.938rem;
                font-weight: 400;
                font-family: "public-sans";
            }
         }

         .template {

            a {
                display: inline-block;
                text-align: center;
                font-size: 1.313rem;

                @media (max-width: 767px) {
                    width: 100%;
                }
            }

            
         }

         .sub-title {
            font-weight: 700;
            font-size: 1.25rem;
            font-family: "public-sans";
            color: #221044;
         }

         .summary-report-container {
         }
    }


    .summary-report-container {

        padding: 2.875rem 0;

        .sub-title {
            font-size: 1.25rem;
            font-weight: 700;
            font-family: "public-sans";
            margin-bottom: 1.188rem;
        }

        .key-point-list {


    
            li {
    
                margin-bottom: 1.5rem;
    
               &:first-of-type {
                margin-bottom: 1.5rem;
               } 
    
               span {
                font-size: 1rem;
                font-weight: 700;
                font-family: "public-sans";
               }
    
               p {
                font-size: 0.938rem;
                margin-bottom: 0;
               }

               .summary-score-title {

                    font-size: 1.375rem !important;
                    font-family: "public-sans";
                    font-weight: 700;
                    margin-bottom: 1.5rem;
                    
                    
                }
            }
        }
    
       

        

    }

    .email {
        background-color: #845BA6;
        color: #221044;
        margin-right: 1rem;
        text-align: center;

        @media (max-width: 767px) {
            width: 100%;
            margin-bottom: 1rem;
        }
    }

    .start-again {
        background-color: #845BA6 !important;
        color: #fff !important;
        text-align: center;

        @media (max-width: 767px) {
            width: 100%;
            margin-bottom: 1rem;
        }
    }

    .questionnaires {
        border-top: 0.063rem solid #221044;
        padding-top: 1rem;
        margin-top: 0.6rem;

        span {
            font-size: 1.125rem !important;

            @media (max-width: 767px) {
                margin-right: 1rem;
            }
        }

        p {
            font-size: 0.938rem;
        }
    }
   
    .score-container {
        padding: 2.875rem 0 3.375rem;  
        background-color: #221044;

    }

    .container {
        max-width: 90% !important;
        @media (max-width: 767px) {
            max-width: unset !important;
        }
    }

    .question-title {
        @media (max-width: 767px) {
            font-size: 0.8rem !important;
        }
    }

    .answer-percent {
        @media (max-width: 767px) {
            font-size: 0.8rem !important;
        } 
    }

    .all-progress {
        width: 88.5%;
        margin-bottom: 3rem;
        align-items: flex-start !important;

        .category-progress-summary {
            margin-left: 3rem;

            @media (max-width: 767px) {
                margin-left: 0;
            }
        }
    }
    
}